import React from 'react';

function Privacypolicy() {

    return (
        <div>
<h1>GALEETMATH Privacy Policy</h1>
      <p>Effective Date: [8/20/2023]</p>
      <p>
        Welcome to GaleetMath.com ("we", "our", or "us"). We are committed to ensuring the privacy and security of your personal information. This Privacy Policy outlines how we collect, use, and protect your data when you use our website.
      </p>
      <h2>Data Collection</h2>
      <p>
  GaleetMath.com, in unwavering dedication to preserving your privacy, has established stringent data protection protocols that uphold the sanctity and security of your personal information. We emphasize that no personally identifiable information (PII) is ever collected, retained, or processed within the realms of our platform. This categorical stance extends to disavowing data mining, user profiling, or personalized tracking practices, as our singular focus is providing an immersive, uncompromised math learning environment. All data amassed during interactions with GaleetMath.com remains entirely non-identifiable, encompassing technical details like browser types, device specifications, and usage patterns, which are meticulously utilized to enhance overall website functionality and user experience. Our commitment to data security is fortified by stringent technical measures, encompassing cutting-edge encryption protocols and firewall mechanisms that ensure your data remains impervious to external threats. We are steadfast in our mission to provide a secure virtual space for mathematical exploration, where your trust is paramount, and your privacy remains an inviolable commitment upheld through rigorous adherence to legal and ethical standards.
</p>
      <h2>Information Usage</h2>
      <p>
  Our unwavering commitment to your privacy translates into a steadfast dedication to ethical and responsible information usage at GaleetMath.com. We want to assure you that we never engage in the use, sale, or sharing of any personally identifiable information (PII) for marketing, advertising, or third-party collaborations. Our sole purpose revolves around offering an unparalleled math learning experience, ensuring that your educational journey remains our exclusive focus without any compromise to your privacy. Any data collected, strictly non-identifiable and limited to technical details like browser types, device specifications, and usage patterns, is employed solely to elevate the functionality and user-friendliness of GaleetMath.com. Our commitment extends to safeguarding your non-identifiable data from unauthorized access or manipulation. Upholding a comprehensive framework of legal and ethical standards, we ensure your trust in GaleetMath.com is well-placed. Your educational pursuit is a revered endeavor we facilitate with utmost integrity, respecting your privacy as an inseparable part of our mission.
</p>
      <h2>Data Security</h2>
      <p>
        We understand the importance of data security and have implemented measures to protect any non-identifiable information that may be collected through standard website usage. Our security protocols ensure that your visit to GaleetMath.com is safe and your data remains private.
      </p>
      <h2>Cookies</h2>
      <p>
        We may use cookies to enhance your browsing experience on GaleetMath.com. These cookies are used to gather non-personal information such as browser type, device type, and pages visited. Cookies help us improve our website's functionality and performance, but they do not track any individual user's information.
      </p>
      <h2>Third-Party Links</h2>
      <p>
        GaleetMath.com may contain links to third-party websites. Please note that this Privacy Policy only applies to our website. We are not responsible for the privacy practices or content of any linked sites. We encourage you to read the privacy policies of any third-party websites you visit.
      </p>
      <h2>Changes to Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes made will be posted on this page, and the effective date will be revised accordingly. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your data.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions, concerns, or feedback regarding this Privacy Policy or our practices, please contact us at [georgialeetmath@gmail.com].
      </p>
      <p>
        Thank you for choosing GaleetMath.com for your math learning needs. Your privacy is our priority, and we are dedicated to providing a safe and secure online learning environment.
      </p>
      </div>
    );
};

export default Privacypolicy;
