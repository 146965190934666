import React, { useState, useEffect, useMemo } from 'react';
import './Test.css';
import './Popup.css';
import '../App.css';
import Incorrectp from './Incorrectp';

const questions = [
		{
			questionText: 'What is the value of the digit 7 in the number 574,381?',
			answerOptions: [
			  { answerText: '7', isCorrect: false },
			  { answerText: '700', isCorrect: false },
			  { answerText: '70,000', isCorrect: true },
			  { answerText: '7,000', isCorrect: false },
			],
			incorrectAnswerMessage: 'The digit 7 is in the ten-thousands place. In the number 574,381, the digit 7 represents 7 * 10,000 = 70,000.',
		  },
		  {
			questionText: 'What is the place value of the digit 6 in the number 845.326?',
			answerOptions: [
			  { answerText: 'Ones', isCorrect: false },
			  { answerText: 'Tens', isCorrect: false },
			  { answerText: 'Hundredths', isCorrect: false },
			  { answerText: 'Thousandths', isCorrect: true },
			],
			incorrectAnswerMessage: 'The digit 6 is in the thousandths place. In the number 845.326, the digit 6 represents 6/1000 = 0.006.',
		  },		  
	  
		// Rounding and Estimation
		{
		  questionText: 'Round 76.54 to the nearest whole number.',
		  answerOptions: [
			{ answerText: '77', isCorrect: true },
			{ answerText: '76', isCorrect: false },
			{ answerText: '75', isCorrect: false },
			{ answerText: '80', isCorrect: false },
		  ],
		  incorrectAnswerMessage: 'The number 76.54 is closer to 77 than to 76, so it rounds to 77.',
		},
		{
			questionText: 'find the product of 85 and 24.',
			answerOptions: [
			  { answerText: '1,000', isCorrect: false },
			  { answerText: '1,100', isCorrect: false },
			  { answerText: '2,500', isCorrect: false },
			  { answerText: '2,040', isCorrect: true },
			],
			incorrectAnswerMessage: 'You can multiply 85 and 24 . 85 * 24 = 2,040. So, the product is 2,040.',
		  },
	  
		// Factors and Multiples
		{
		  questionText: 'What is the greatest common factor of 24 and 36?',
		  answerOptions: [
			{ answerText: '4', isCorrect: false },
			{ answerText: '12', isCorrect: true },
			{ answerText: '24', isCorrect: false },
			{ answerText: '6', isCorrect: false },
		  ],
		  incorrectAnswerMessage: 'The factors of 24 are 1, 2, 3, 4, 6, 8, 12, and 24. The factors of 36 are 1, 2, 3, 4, 6, 9, 12, 18, and 36. The greatest common factor (GCF) is the largest number that divides both 24 and 36. In this case, the GCF is 12.',
		},
		{
		  questionText: 'What is the least common multiple of 9 and 15?',
		  answerOptions: [
			{ answerText: '45', isCorrect: true },
			{ answerText: '30', isCorrect: false },
			{ answerText: '90', isCorrect: false },
			{ answerText: '60', isCorrect: false },
		  ],
		  incorrectAnswerMessage: 'The multiples of 9 are 9, 18, 27, 36, 45, 54, 63, 72, 81, 90, and so on. The multiples of 15 are 15, 30, 45, 60, 75, 90, and so on. The least common multiple (LCM) is the smallest number that is a multiple of both 9 and 15. In this case, the LCM is 45.',
		},
	  
		// Exponents and Scientific Notation
		{
		  questionText: 'Evaluate 2^4.',
		  answerOptions: [
			{ answerText: '6', isCorrect: false },
			{ answerText: '8', isCorrect: false },
			{ answerText: '16', isCorrect: true },
			{ answerText: '32', isCorrect: false },
		  ],
		  incorrectAnswerMessage: '2^4 means 2 raised to the power of 4. It can be calculated as 2 * 2 * 2 * 2 = 16.',
		},
		{
		  questionText: 'Write 0.0032 in scientific notation.',
		  answerOptions: [
			{ answerText: '3.2 * 10^3', isCorrect: false },
			{ answerText: '3.2 * 10^-2', isCorrect: false },
			{ answerText: '3.2 * 10^-3', isCorrect: true },
			{ answerText: '32 * 10^-4', isCorrect: false },
		  ],
		  incorrectAnswerMessage: 'Scientific notation is of the form "a * 10^b," where "a" is a number greater than or equal to 1 and less than 10, and "b" is an integer. In this case, 0.0032 can be written as 3.2 * 10^-3.',
		},
	  
	  
		// Order of Operations
		{
		  questionText: 'Evaluate the expression: 5 + 3 * 2.',
		  answerOptions: [
			{ answerText: '16', isCorrect: false },
			{ answerText: '11', isCorrect: true },
			{ answerText: '13', isCorrect: false },
			{ answerText: '10', isCorrect: false },
		  ],
		  incorrectAnswerMessage: 'According to the order of operations (PEMDAS), multiplication is done before addition. So, 3 * 2 is calculated first, resulting in 6. Then, 5 + 6 equals 11.',
		},
		{
		  questionText: 'Simplify the expression: 3 + 4 * 2 - 6 ÷ 3.',
		  answerOptions: [
			{ answerText: '9', isCorrect: true },
			{ answerText: '8', isCorrect: false },
			{ answerText: '6', isCorrect: false },
			{ answerText: '5', isCorrect: false },
		  ],
		  incorrectAnswerMessage: 'Again, using the order of operations (PEMDAS), the division is done before the addition and subtraction. First, 6 ÷ 3 is calculated as 2. Then, 4 * 2 is 8. Finally, the expression simplifies to 3 + 8 - 2 = 11 - 2 = 9.',
		},
	  
		// Fractions and Decimals
		{
		  questionText: 'Which of the following decimals is equivalent to 2/5?',
		  answerOptions: [
			{ answerText: '0.04', isCorrect: false },
			{ answerText: '-0.8', isCorrect: false },
			{ answerText: '0.4', isCorrect: true },
			{ answerText: '0.008', isCorrect: false },
		  ],
		  incorrectAnswerMessage: 'To find the decimal equivalent of a fraction, divide the numerator by the denominator. In this case, 2 ÷ 5 is 0.4. Since the fraction is 2/5, the answer should be positive, so the correct decimal is 0.4. The correct answer is 0.4.',
		},
		{
		  questionText: 'Convert the fraction 5/8 to a decimal.',
		  answerOptions: [
			{ answerText: '0.5', isCorrect: false },
			{ answerText: '0.625', isCorrect: true },
			{ answerText: '0.75', isCorrect: false },
			{ answerText: '0.8', isCorrect: false },
		  ],
		  incorrectAnswerMessage: 'To convert a fraction to a decimal, divide the numerator by the denominator. In this case, 5 ÷ 8 is 0.625.',
		},
	  
		// Integers
		{
		  questionText: 'What is the sum of -5 and 7?',
		  answerOptions: [
			{ answerText: '2', isCorrect: true },
			{ answerText: '-2', isCorrect: false },
			{ answerText: '-12', isCorrect: false },
			{ answerText: '12', isCorrect: false },
		  ],
		  incorrectAnswerMessage: 'To add integers with different signs, subtract the smaller absolute value from the larger absolute value and keep the sign of the larger absolute value. In this case, |-5| = 5 and |7| = 7. Since 7 has the larger absolute value, the sum is 7 - 5 = 2.',
		},
		{
		  questionText: 'Which of the following is a negative integer?',
		  answerOptions: [
			{ answerText: '0', isCorrect: false },
			{ answerText: '-1', isCorrect: true },
			{ answerText: '1', isCorrect: false },
			{ answerText: '2', isCorrect: false },
		  ],
		  incorrectAnswerMessage: 'A negative integer is a whole number less than zero. In this case, -1 is a negative integer.',
		},
	  
		// Percentages
		{
		  questionText: 'What is 25% of 80?',
		  answerOptions: [
			{ answerText: '20', isCorrect: true },
			{ answerText: '0.25', isCorrect: false },
			{ answerText: '100', isCorrect: false },
			{ answerText: '5', isCorrect: false },
		  ],
		  incorrectAnswerMessage: 'To find a percentage of a number, multiply the number by the percentage as a decimal. In this case, 25% is equal to 0.25 as a decimal. So, 0.25 * 80 = 20.',
		},
		{
		  questionText: 'What percentage of 120 is 36?',
		  answerOptions: [
			{ answerText: '25%', isCorrect: false },
			{ answerText: '30%', isCorrect: true },
			{ answerText: '20%', isCorrect: false },
			{ answerText: '35%', isCorrect: false },
		  ],
		  incorrectAnswerMessage: 'To find the percentage, divide the part by the whole and multiply by 100. In this case, 36 ÷ 120 = 0.3. Multiply by 100 to get 30%. So, 36 is 30% of 120.',
		},
	  
		// Ratios and Proportions
		{
		  questionText: 'If the ratio of boys to girls in a class is 3:5, and there are 15 boys, how many girls are there?',
		  answerOptions: [
			{ answerText: '9', isCorrect: false },
			{ answerText: '12', isCorrect: false },
			{ answerText: '18', isCorrect: false },
			{ answerText: '25', isCorrect: true },
		  ],
		  incorrectAnswerMessage: 'To find the number of girls, use the ratio of boys to girls. In this case, the ratio is 3:5. Divide 15 (number of boys) by 3 to get 5. Then, multiply 5 by 5 (number of girls for each group of 3 boys) to get 25. So, there are 25 girls in the class.',
		},
		{
		  questionText: 'Solve the proportion: 4/6 = x/12.',
		  answerOptions: [
			{ answerText: 'x = 2', isCorrect: false },
			{ answerText: 'x = 3', isCorrect: false },
			{ answerText: 'x = 8', isCorrect: true },
			{ answerText: 'x = 6', isCorrect: false },
		  ],
		  incorrectAnswerMessage: 'To solve a proportion, cross-multiply and then divide. In this case, cross-multiply: 4 * 12 = 6 * x. Then, divide to find x: 48 = 6x. Divide both sides by 6 to get x = 8.',
		},
	  
		// Absolute Value and Opposites
		{
		  questionText: 'What is the absolute value of -9?',
		  answerOptions: [
			{ answerText: '9', isCorrect: true },
			{ answerText: '-9', isCorrect: false },
			{ answerText: '0', isCorrect: false },
			{ answerText: '1', isCorrect: false },
		  ],
		  incorrectAnswerMessage: 'The absolute value of a number is its distance from zero on the number line. In this case, the absolute value of -9 is 9.',
		},
		{
		  questionText: 'What is the opposite of -3?',
		  answerOptions: [
			{ answerText: '3', isCorrect: true },
			{ answerText: '-3', isCorrect: false },
			{ answerText: '0', isCorrect: false },
			{ answerText: '1', isCorrect: false },
		  ],
		  incorrectAnswerMessage: 'The opposite of a number is the number with the same absolute value but the opposite sign. In this case, the opposite of -3 is 3.',
		},
	  
		{
		  incorrectAnswerMessage: 'Sorry, that was incorrect. The cube root of 125 is the number that, when multiplied by itself three times, results in 125. In this case, the cube root of 125 is 5, because 5 x 5 x 5 = 125.',
		  questionText: 'What is the cube root of 125?',
		  answerOptions: [
			{ answerText: '5', isCorrect: true },
			{ answerText: '3', isCorrect: false },
			{ answerText: '4', isCorrect: false },
			{ answerText: '6', isCorrect: false },
		  ],
		},
		{
			incorrectAnswerMessage: 'Sorry, that was incorrect. The sum of 7 and 9 is calculated as 7 + 9 = 16.',
			questionText: 'What is the sum of 7 and 9?',
			answerOptions: [
			  { answerText: '16', isCorrect: true },
			  { answerText: '14', isCorrect: false },
			  { answerText: '15', isCorrect: false },
			  { answerText: '18', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The perimeter of a rectangle can be calculated as 2 * (length + width). In this case, the perimeter is 2 * (6 + 8) = 28 units.',
			questionText: 'What is the perimeter of a rectangle with length 6 units and width 8 units?',
			answerOptions: [
			  { answerText: '42', isCorrect: false },
			  { answerText: '30', isCorrect: false },
			  { answerText: '28', isCorrect: true },
			  { answerText: '20', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The reciprocal of 1/4 is obtained by flipping the fraction, resulting in 4/1 = 4.',
			questionText: 'What is the reciprocal of 1/4?',
			answerOptions: [
			  { answerText: '1/2', isCorrect: false },
			  { answerText: '2/1', isCorrect: false },
			  { answerText: '4/1', isCorrect: true },
			  { answerText: '1/4', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The quotient of 18 divided by 3 is calculated as 18 / 3 = 6.',
			questionText: 'What is the quotient of 18 divided by 3?',
			answerOptions: [
			  { answerText: '5', isCorrect: false },
			  { answerText: '6', isCorrect: true },
			  { answerText: '4', isCorrect: false },
			  { answerText: '7', isCorrect: false },
			],
		  },
		{
		  incorrectAnswerMessage: 'Sorry, that was incorrect. If x = 15, 3x + 10 can be calculated as 3 * 15 + 10 = 55.',
		  questionText: 'If x = 15, what is 3x + 10?',
		  answerOptions: [
			{ answerText: '55', isCorrect: true },
			{ answerText: '40', isCorrect: false },
			{ answerText: '35', isCorrect: false },
			{ answerText: '45', isCorrect: false },
		  ],
		},
		{
		  incorrectAnswerMessage: 'Sorry, that was incorrect. The sum of 4 and 7 is calculated as 4 + 7 = 11.',
		  questionText: 'What is the sum of 4 and 7?',
		  answerOptions: [
			{ answerText: '9', isCorrect: false },
			{ answerText: '11', isCorrect: true },
			{ answerText: '12', isCorrect: false },
			{ answerText: '8', isCorrect: false },
		  ],
		},
		{
		  incorrectAnswerMessage: 'Sorry, that was incorrect. In a right-angled triangle with sides 3 and 4 units long, you can use the Pythagorean theorem to find the length of the hypotenuse. The Pythagorean theorem states that the square of the hypotenuse (c) is equal to the sum of the squares of the other two sides (a and b). In this case, the length of the hypotenuse (c) can be calculated as c = sqrt(3^2 + 4^2) = sqrt(9 + 16) = sqrt(25) = 5.',
		  questionText: 'In a right-angled triangle with sides 3 and 4 units long, what is the length of the hypotenuse?',
		  answerOptions: [
			{ answerText: '3', isCorrect: false },
			{ answerText: '4', isCorrect: false },
			{ answerText: '5', isCorrect: true },
			{ answerText: '6', isCorrect: false },
		  ],
		},

	  
		// Order of Operations
		{
		  questionText: 'Evaluate the expression: 5 + 3 * 2.',
		  answerOptions: [
			{ answerText: '16', isCorrect: false },
			{ answerText: '11', isCorrect: true },
			{ answerText: '13', isCorrect: false },
			{ answerText: '10', isCorrect: false },
		  ],
		  incorrectAnswerMessage: 'According to the order of operations (PEMDAS), multiplication is done before addition. So, 3 * 2 is calculated first, resulting in 6. Then, 5 + 6 equals 11.',
		},
		{
		  questionText: 'Simplify the expression: 3 + 4 * 2 - 6 ÷ 3.',
		  answerOptions: [
			{ answerText: '9', isCorrect: true },
			{ answerText: '8', isCorrect: false },
			{ answerText: '6', isCorrect: false },
			{ answerText: '5', isCorrect: false },
		  ],
		  incorrectAnswerMessage: 'Again, using the order of operations (PEMDAS), the division is done before the addition and subtraction. First, 6 ÷ 3 is calculated as 2. Then, 4 * 2 is 8. Finally, the expression simplifies to 3 + 8 - 2 = 9 - 2 = 9.',
		},
	  
	  ];
			
	  export default function NumberOper() {

	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [showScore, setShowScore] = useState(false);
	const [score, setScore] = useState(0);
	const [showPopup, setShowPopup] = useState(false);
	const [popupMessage, setPopupMessage] = useState('');

	const shuffledQuestions = useMemo(() => {
		// Function to shuffle the questions array
		const shuffleQuestions = () => {
		  const shuffledQuestions = [...questions];
		  for (let i = shuffledQuestions.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[shuffledQuestions[i], shuffledQuestions[j]] = [
			  shuffledQuestions[j],
			  shuffledQuestions[i],
			];
		  }
		  return shuffledQuestions;
		};
	
		const result = shuffleQuestions();
		console.log(result); // Add this line to log the shuffledQuestions
		return result;

	  }, []);

	  useEffect(() => {
		// The effect logic here (if needed)
	  }, [shuffledQuestions]);

	const handleAnswerOptionClick = (isCorrect, incorrectAnswerMessage) => {
		if (isCorrect) {
		  setScore(score + 1);
		} else {
		  setShowPopup(true);
		  setPopupMessage(incorrectAnswerMessage);
		}
	  
		const nextQuestion = currentQuestion + 1;
		if (nextQuestion < questions.length) {
		  setCurrentQuestion(nextQuestion);
		} else {
		  setShowScore(true);
		}
	  };
	  
	
	  const handleClosePopup = () => {
		// Close the pop-up and proceed to the next question
		setShowPopup(false);
		setCurrentQuestion(currentQuestion);
		setPopupMessage('');
	  };
	
	  return (
		<div className='entireT'>
		  <div style={{ textAlign: 'center', marginBottom: '20px' }}>
			<h2>Numbers And Operations Math Practice</h2>
			Take notes for incorrect answers.
		  </div>
		  <div className='Test'>
			{showScore ? (
			  <div className='score-section'>
				You scored {score} out of {shuffledQuestions.length}
			  </div>
			) : currentQuestion < shuffledQuestions.length ? (
			  <>
				<div className='question-section'>
				  <div className='question-count'>
					<span>Question {currentQuestion + 1}</span>/{shuffledQuestions.length}
				  </div>
				  <div className='question-text'>
					{shuffledQuestions[currentQuestion].questionText}
				  </div>
				</div>
				
				<div className='answer-section'>
				  {shuffledQuestions[currentQuestion].answerOptions.map((answerOption) => (
					<button
					  key={answerOption.answerText}
					  onClick={() =>
						handleAnswerOptionClick(
						  answerOption.isCorrect,
						  shuffledQuestions[currentQuestion].incorrectAnswerMessage
						)
					  }
					>
					  {answerOption.answerText}
					</button>
				  ))}
				</div>
			  </>
			) : null}
			
			{showPopup && (
			  <div className='popup-card'>
				<Incorrectp message={popupMessage} onClose={handleClosePopup} />
			  </div>
			)}
		  </div>
		</div>
	  );
			}	  