import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore, collection, addDoc, query, where, getDocs } from "firebase/firestore";


export const auth = getAuth();
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore();

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const name = result.user.displayName;
    const email = result.user.email;

    // Store the user's name and email in localStorage for use in the app
    localStorage.setItem("name", name);
    localStorage.setItem("email", email);

    // Check if the user document already exists in Firestore
    const userRef = collection(db, "users");
    const querySnapshot = await query(userRef, where("email", "==", email));
    if (querySnapshot.size === 0) {
      // If no document exists, create it with initial score
      await addDoc(userRef, {
        name: name,
        email: email,
        score: 0, // Set initial score to 0
      });
    }
  } catch (error) {
    console.error("Error signing in with Google:", error);
  }
};

export const getUsers = async () => {
  try {
    const userSnapshot = await getDocs(collection(db, "users"));
    const users = userSnapshot.docs.map((doc) => doc.data());
    return users;
  } catch (error) {
    console.error("Error getting users:", error);
    return [];
  }
};
