import React, { useState, useEffect } from 'react';
import './CEa.css';
import { Link } from 'react-router-dom';
import { db, auth } from '../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';


function CGb() {
  const [timeLeft, setTimeLeft] = useState(45);
  const [inputValue, setInputValue] = useState('');
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [timerExpired, setTimerExpired] = useState(false);
  const [completed, setCompleted] = useState(false);

  const user = auth.currentUser;

  useEffect(() => {
    const checkCGBField = async () => {
      try {
        if (user) {
          const userRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(userRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            const cgbValue = userData.cgb;

            if (cgbValue === 1) {
              setInputValue('completed');
              setShowSubmitButton(false);
            }
          }
        }
      } catch (error) {
        console.error('Error checking CGB field:', error);
      }
    };

    checkCGBField();
  }, [user]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      } else {
        clearInterval(timer);
        setTimerExpired(true);
        setShowSubmitButton(false);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [timeLeft]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);

    if (event.target.value === '32') {
      setShowSubmitButton(true);
    } else {
      setShowSubmitButton(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const userRef = doc(db, 'users', user.uid);
      await setDoc(userRef, { cgb: 1 }, { merge: true });
      console.log('CGA field updated to 1');
      setShowSubmitButton(false);
      setCompleted(true);
    } catch (error) {
      console.error('Error updating CGB field:', error);
    }
  };

  return (
    <div className="card">
      <div className="timer">
        {!timerExpired ? `${timeLeft} seconds` : 'Timer Ran Out'}
      </div>
      {completed ? (
        <>
          <p>A square has a side length of 8 units. What is the perimeter of the square?
          </p>
          <p>Completed</p>
        </>
      ) : inputValue === 'completed' ? (
        <>
          <p>A square has a side length of 8 units. What is the perimeter of the square?
          </p>
          <p>Completed</p>
        </>
      ) : (
        <>
          <p>A square has a side length of 8 units. What is the perimeter of the square?
          </p>
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            disabled={timerExpired}
          />
          {showSubmitButton && (
            <button className="submit-button" onClick={handleSubmit}>
              Submit
            </button>
          )}
        </>
      )}
      <Link to="/CGeometry" className="back-button">
        Back
      </Link>
    </div>    
  );
  
}

export default CGb;
