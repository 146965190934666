import React from 'react';
import './TransformationsTutorial.css';

const TransformationsTutorial = () => {
  return (
    <div className="transformations-tutorial">
      <h1>Transformations Tutorial</h1>
      
      <p><strong>Introduction to Transformations:</strong></p>
      <p>Transformations are operations that change the position, orientation, or size of a shape on a coordinate plane. There are four main types of transformations:</p>
      <ul>
        <li>Translation</li>
        <li>Reflection</li>
        <li>Rotation</li>
        <li>Dilation</li>
      </ul>
      <p>Each type of transformation has a specific effect on the shape, and they can be described using both words and numbers.</p>

      <p><strong>Translation:</strong></p>
      <p>Translation moves a shape from one location to another without changing its size or orientation. It involves shifting the shape along the x-axis and/or y-axis. The notation (x, y) + (a, b) represents a translation of 'a' units horizontally and 'b' units vertically.</p>

      <p><strong>Reflection:</strong></p>
      <p>Reflection flips a shape over a line (called the line of reflection) to create a mirror image. The line of reflection acts as the mirror, and points on the shape are equidistant from the line before and after the reflection.</p>

      <p><strong>Rotation:</strong></p>
      <p>Rotation turns a shape around a point (called the center of rotation) by a certain angle. Positive angles indicate counterclockwise rotation, while negative angles indicate clockwise rotation. The notation (x, y) &#x2192; (x', y') represents a rotation of a point by the angle of rotation.</p>

      <p><strong>Dilation:</strong></p>
      <p>Dilation changes the size of a shape while keeping its shape and orientation. It involves scaling the shape by a factor. The notation (x, y) &#x2192; (kx, ky) represents a dilation where 'k' is the scale factor.</p>

      <p><strong>Example:</strong></p>
      <p>Let's take the point A(3, 2) and apply different transformations:</p>
      <ul>
        <li>Translation by (2, 4): A' &#x2192; (3 + 2, 2 + 4) = (5, 6)</li>
        <li>Reflection over x-axis: A' &#x2192; (3, -2)</li>
        <li>Rotation by 90 degrees counterclockwise: A' &#x2192; (-2, 3)</li>
        <li>Dilation by a scale factor of 2: A' &#x2192; (6, 4)</li>
      </ul>

      <p><strong>Practice Questions:</strong></p>
<p>1. Describe the transformation that moves a triangle from (2, 3) to (5, 8).</p>
<p><em>Answer: </em>This is a translation by (3, 5). The triangle has been shifted 3 units to the right along the x-axis and 5 units up along the y-axis.</p>

<p>2. If a shape is reflected over the y-axis, what happens to the x-coordinates of its points?</p>
<p><em>Answer: </em>The x-coordinates of the shape's points are negated or multiplied by -1. Points that were on the right side of the y-axis will now be on the left side, and vice versa.</p>

<p>3. Rotate the point B(4, -1) by 180 degrees counterclockwise.</p>
<p><em>Answer: </em>After a 180-degree counterclockwise rotation, the point B(4, -1) will become B'(-4, 1).</p>

<p>4. Apply a dilation with a scale factor of 0.5 to the point C(-6, 2).</p>
<p><em>Answer: </em>After a dilation with a scale factor of 0.5, the point C(-6, 2) will become C'(-3, 1). Both the x-coordinate and y-coordinate are halved.</p>
    </div>
  );
};

export default TransformationsTutorial;
