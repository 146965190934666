import React from 'react';
import { Link } from 'react-router-dom';
import './About.css';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

const About = () => {
  return (
    <div>
    <div className="social-media-icons">
      <a href="https://www.facebook.com/profile.php?id=61550788475462"target="_blank" rel="noopener noreferrer">
        <FaFacebook />
      </a>
      <a href="https://twitter.com/galeetmath"target="_blank" rel="noopener noreferrer">
        <FaTwitter />
      </a>
      <a href="https://www.instagram.com/galeetmath"target="_blank" rel="noopener noreferrer">
        <FaInstagram />
      </a>
      </div>
      <center><div className='ldrbbutton'>
      <Link to="/leaderboard" className="navbar-link">Leaderboard</Link>
      </div></center>
    <div className="about-container">
      <p><strong>The Transformative Power of Early Math Education</strong></p>

<p>In a rapidly evolving world driven by technology and innovation, the significance of a solid foundation in mathematics cannot be overstated, particularly for the younger generation. Early exposure to math skills not only sets the stage for academic success but also cultivates critical thinking, problem-solving abilities, and a resilient mindset. This informative writing piece delves into the positive effects that math skills exert on young people, underscoring the pivotal role they play in shaping a brighter future.</p>

<p><strong>Enhancing Cognitive Abilities</strong></p>

<p>Mathematics serves as an intellectual workout for young minds, fostering the development of crucial cognitive skills. A study conducted by the National Institute of Child Health and Human Development (NICHD) found that early math skills are strong predictors of later academic achievement. As children engage with mathematical concepts, they enhance their spatial reasoning, memory retention, and logical thinking capabilities. These cognitive skills not only bolster their performance in math-related subjects but also permeate into other areas of learning, enabling them to excel in diverse fields.</p>

<p><strong>Nurturing Problem-Solving Skills</strong></p>

<p>At its core, mathematics is about problem-solving – a skill that has universal applicability across disciplines and life situations. According to a report by the World Economic Forum, problem-solving is identified as a crucial skill for the workforce of the future. Early exposure to math equips young individuals with the tools to approach challenges methodically and develop creative solutions. Through math education, they learn to analyze problems, break them down into manageable components, and apply systematic strategies, all of which are invaluable assets in the modern job market.</p>

<p><strong>Statistics Fact 1:</strong> A survey conducted by the Program for International Student Assessment (PISA) indicated that countries with higher math scores among 15-year-olds tend to have lower unemployment rates among young adults. This underscores the correlation between early math proficiency and improved career prospects.</p>

<p><strong>Fostering Financial Literacy</strong></p>

<p>In an era of complex financial decisions and digital transactions, numeracy skills are paramount for making informed choices. A report from the Organisation for Economic Co-operation and Development (OECD) highlighted the significance of financial literacy among young people, emphasizing the role of math education in cultivating this literacy. Understanding concepts such as percentages, interest rates, and budgeting equips individuals with the ability to manage their finances effectively and make wise economic decisions throughout their lives.</p>

<p><strong>Building Resilience and Confidence</strong></p>

<p>Mathematics often poses challenges that require perseverance and determination to overcome. As young learners grapple with intricate problems, they develop a resilient mindset, learning to embrace setbacks as opportunities for growth. Mastering mathematical concepts fosters a sense of accomplishment, boosting self-confidence and self-esteem. This newfound confidence transcends the realm of math, enabling young individuals to tackle uncertainties with courage and tenacity.</p>

<p><strong>Statistics Fact 2:</strong> Research conducted by Stanford University's Mindset Scholars Network revealed that students who perceive their math abilities positively are more likely to pursue STEM (science, technology, engineering, and mathematics) careers, contributing to the diversification of these fields.</p>

<p><strong>Conclusion</strong></p>

<p>The positive effects of early math education on young people extend far beyond the classroom, influencing their cognitive development, problem-solving skills, financial literacy, and overall resilience. Equipped with a strong mathematical foundation, they are primed to navigate the complexities of the modern world and seize opportunities for a brighter future. As educators, parents, and society as a whole, it is essential to recognize the transformative power of math skills in shaping the next generation of innovators, critical thinkers, and well-rounded individuals.</p>

<p><strong>Sources:</strong></p>
<ol>
    <li>National Institute of Child Health and Human Development (NICHD) Study: <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4258143/">Early Mathematics Proficiency and Later Achievement</a></li>
    <li>World Economic Forum Report: <a href="https://www.weforum.org/reports/the-future-of-jobs-report-2020">The Future of Jobs Report 2020</a></li>
    <li>Organisation for Economic Co-operation and Development (OECD) Report: <a href="https://www.oecd.org/pisa/data/PISA-2018-Financial-Literacy-Framework-ENG.pdf">PISA Financial Literacy Assessment Framework</a></li>
    <li>Stanford University's Mindset Scholars Network Research: <a href="https://ed.stanford.edu/sites/default/files/istss.pdf">Who Believes They Can Get Smarter? Implicit Theories of Intelligence Predict Academic Achievement and Performance</a></li>
</ol>
      
      <p className="about-text">
        Provide the next generation with the upper hand.
      </p>
      <p className="about-text">
        We are dedicated to supporting students, teachers,
        and parents in achieving academic excellence. Join us on the journey of learning and mastering
        the art of mathematics!
      </p>
      <p className="about-text">
        Email georgialeetmath@gmail.com
      </p>
      
    </div>
    <br></br><br></br>
    <div>2023 Copyright Georgia Leet Math <Link to="/Privacypolicya">Privacy Policy</Link></div>
        </div>
  );
};

export default About;
