import React, { useState, useEffect, useMemo, useCallback } from 'react';
import './Test.css';
import '../App.css';
import { db } from "../firebase";
import {
  doc,
  getDoc,
  updateDoc,
  setDoc
} from "firebase/firestore";
import { auth } from "../firebase";



	const questions = [
		{
		  questionText: 'In a bag, there are 4 red marbles, 3 blue marbles, and 5 green marbles. If you randomly select one marble, what is the probability of getting a blue marble?',
		  answerOptions: [
			{ answerText: '1/3', isCorrect: false },
			{ answerText: '1/4', isCorrect: true },
			{ answerText: '3/4', isCorrect: false },
			{ answerText: '2/5', isCorrect: false },
		  ],
		},
		{
		  questionText: 'In a survey, 100 students were asked about their favorite sport. 40 students chose soccer, 30 chose basketball, and the rest chose volleyball. How many students chose volleyball?',
		  answerOptions: [
			{ answerText: '20', isCorrect: false },
			{ answerText: '30', isCorrect: true },
			{ answerText: '40', isCorrect: false },
			{ answerText: '10', isCorrect: false },
		  ],
		},
		{
		  questionText: 'A fair six-sided dice is rolled. What is the probability of rolling an odd number?',
		  answerOptions: [
			{ answerText: '1/6', isCorrect: false },
			{ answerText: '1/2', isCorrect: true },
			{ answerText: '2/3', isCorrect: false },
			{ answerText: '1/3', isCorrect: false },
		  ],
		},
		{
		  questionText: 'The heights (in inches) of a group of students are: 55, 58, 60, 62, 60, 63, 59, 64, 61, 58. What is the mean height of the students?',
		  answerOptions: [
			{ answerText: '58.2', isCorrect: false },
			{ answerText: '59', isCorrect: false },
			{ answerText: '57', isCorrect: false },
			{ answerText: '60', isCorrect: true },
		  ],
		},

		// Fractions and Decimals (Continued)
{
	questionText: 'Which of the following decimals is equivalent to 2/5?',
	answerOptions: [
	  { answerText: '0.25', isCorrect: false },
	  { answerText: '0.2', isCorrect: false },
	  { answerText: '0.4', isCorrect: true },
	  { answerText: '0.5', isCorrect: false },
	],
  },
  {
	questionText: 'Express 3/8 as a decimal.',
	answerOptions: [
	  { answerText: '0.375', isCorrect: true },
	  { answerText: '0.35', isCorrect: false },
	  { answerText: '0.38', isCorrect: false },
	  { answerText: '0.325', isCorrect: false },
	],
  },
  
  // Integers (Continued)
  {
	questionText: 'What is the sum of -5 and 7?',
	answerOptions: [
	  { answerText: '2', isCorrect: true },
	  { answerText: '-2', isCorrect: false },
	  { answerText: '-12', isCorrect: false },
	  { answerText: '12', isCorrect: false },
	],
  },
  {
	questionText: 'Which of the following is a negative integer?',
	answerOptions: [
	  { answerText: '0', isCorrect: false },
	  { answerText: '-1', isCorrect: true },
	  { answerText: '1', isCorrect: false },
	  { answerText: '2', isCorrect: false },
	],
  },
  
  // Equations and Inequalities (Continued)
  {
	questionText: 'Solve for x: 3x - 7 = 8',
	answerOptions: [
	  { answerText: 'x = 5', isCorrect: true },
	  { answerText: 'x = 4', isCorrect: false },
	  { answerText: 'x = 3', isCorrect: false },
	  { answerText: 'x = 2', isCorrect: false },
	],
  },
  {
	questionText: 'Which of the following inequalities is true for all values of x?',
	answerOptions: [
	  { answerText: '2x - 3 < 4x - 1', isCorrect: true },
	  { answerText: '3x + 5 > 2x + 8', isCorrect: false },
	  { answerText: '4x - 2 < 2x + 7', isCorrect: false },
	  { answerText: 'x + 5 < 3x - 1', isCorrect: false },
	],
  },
  
				{
				  questionText: 'Solve for x: 2x + 5 = 15',
				  answerOptions: [
					{ answerText: 'x = 10', isCorrect: false },
					{ answerText: 'x = 7', isCorrect: false },
					{ answerText: 'x = 12', isCorrect: false },
					{ answerText: 'x = 5', isCorrect: true },
				  ],
				},
				{
				  questionText: 'What is the value of 3(x + 4) when x = 6?',
				  answerOptions: [
					{ answerText: '21', isCorrect: false },
					{ answerText: '27', isCorrect: false },
					{ answerText: '18', isCorrect: false },
					{ answerText: '30', isCorrect: true },
				  ],
				},
			  
				// Geometry
				{
				  questionText: 'What is the measure of each interior angle of a regular hexagon?',
				  answerOptions: [
					{ answerText: '120 degrees', isCorrect: true },
					{ answerText: '90 degrees', isCorrect: false },
					{ answerText: '60 degrees', isCorrect: false },
					{ answerText: '180 degrees', isCorrect: false },
				  ],
				},
				{
				  questionText: 'In a right triangle, what is the length of the hypotenuse if the other two sides have lengths 5 and 12?',
				  answerOptions: [
					{ answerText: '13', isCorrect: true },
					{ answerText: '17', isCorrect: false },
					{ answerText: '10', isCorrect: false },
					{ answerText: '15', isCorrect: false },
				  ],
				},
			  
				// Data and Probability
				{
				  questionText: 'In a class of 30 students, 18 play basketball, 12 play soccer, and 8 play both. How many students play either basketball or soccer?',
				  answerOptions: [
					{ answerText: '22', isCorrect: true },
					{ answerText: '24', isCorrect: false },
					{ answerText: '28', isCorrect: false },
					{ answerText: '30', isCorrect: false },
				  ],
				},
				{
				  questionText: 'A coin is flipped three times. What is the probability of getting heads on all three flips?',
				  answerOptions: [
					{ answerText: '1/8', isCorrect: true },
					{ answerText: '1/4', isCorrect: false },
					{ answerText: '1/2', isCorrect: false },
					{ answerText: '3/8', isCorrect: false },
				  ],
				},
			  
				// Number and Operations
				{
				  questionText: 'What is the least common multiple (LCM) of 6 and 8?',
				  answerOptions: [
					{ answerText: '12', isCorrect: false },
					{ answerText: '24', isCorrect: true },
					{ answerText: '16', isCorrect: false },
					{ answerText: '18', isCorrect: false },
				  ],
				},
				{
				  questionText: 'What is the value of 2^4?',
				  answerOptions: [
					{ answerText: '4', isCorrect: false },
					{ answerText: '16', isCorrect: true },
					{ answerText: '8', isCorrect: false },
					{ answerText: '32', isCorrect: false },
				  ],
				},
			  
				// Ratios and Proportions
				{
				  questionText: 'If 4 gallons of paint can cover a wall in 5 hours, how long will it take to cover the same wall with 8 gallons of paint?',
				  answerOptions: [
					{ answerText: '2 hours', isCorrect: false },
					{ answerText: '10 hours', isCorrect: true },
					{ answerText: '4 hours', isCorrect: false },
					{ answerText: '20 hours', isCorrect: false },
				  ],
				},
				{
				  questionText: 'If a car travels 300 miles in 6 hours, how many miles will it travel in 10 hours at the same speed?',
				  answerOptions: [
					{ answerText: '500 miles', isCorrect: true },
					{ answerText: '450 miles', isCorrect: false },
					{ answerText: '600 miles', isCorrect: false },
					{ answerText: '250 miles', isCorrect: false },
				  ],
				},
			  
				// Fractions and Decimals
				{
				  questionText: 'Which of the following fractions is equivalent to 3/4?',
				  answerOptions: [
					{ answerText: '1/2', isCorrect: false },
					{ answerText: '1/3', isCorrect: false },
					{ answerText: '5/6', isCorrect: false },
					{ answerText: '6/8', isCorrect: true },
				  ],
				},
				{
				  questionText: 'Express 0.875 as a fraction in simplest form.',
				  answerOptions: [
					{ answerText: '7/10', isCorrect: false },
					{ answerText: '7/8', isCorrect: true },
					{ answerText: '5/8', isCorrect: false },
					{ answerText: '5/7', isCorrect: false },
				  ],
				},
			  
				// Integers
				{
				  questionText: 'Which of the following numbers is a positive integer?',
				  answerOptions: [
					{ answerText: '-2', isCorrect: false },
					{ answerText: '0', isCorrect: false },
					{ answerText: '1', isCorrect: true },
					{ answerText: '-1', isCorrect: false },
				  ],
				},
				{
				  questionText: 'Evaluate: 12 - 5 * 2',
				  answerOptions: [
					{ answerText: '4', isCorrect: false },
					{ answerText: '14', isCorrect: false },
					{ answerText: '2', isCorrect: true },
					{ answerText: '12', isCorrect: false },
				  ],
				},
			  
				// Equations and Inequalities
				{
				  questionText: 'Which of the following equations is true for all values of x?',
				  answerOptions: [
					{ answerText: '3x - 5 = 2x', isCorrect: false },
					{ answerText: '2x + 5 = 4x - 2', isCorrect: false },
					{ answerText: '2x + 7 = 7x + 5', isCorrect: false },
					{ answerText: '2x = x + x', isCorrect: true },
				  ],
				},
				{
				  questionText: 'Which of the following inequalities is true for all values of x?',
				  answerOptions: [
					{ answerText: '(x + 1)(x - 1) = x^2 - 1', isCorrect: true },
					{ answerText: '4x + 5 < 2x + 10', isCorrect: false },
					{ answerText: '5x - 6 >= 3x + 1', isCorrect: false },
					{ answerText: '5x - 3 <= 5x + 2', isCorrect: false },
				  ],
				},
			  
				// Percents
				{
				  questionText: 'What is 40% of 250?',
				  answerOptions: [
					{ answerText: '95', isCorrect: false },
					{ answerText: '90', isCorrect: false },
					{ answerText: '60', isCorrect: false },
					{ answerText: '100', isCorrect: true },
				  ],
				},
				{
				  questionText: 'If the original price of a shirt was $25 and it is on sale for 20% off, what is the sale price?',
				  answerOptions: [
					{ answerText: '$15', isCorrect: false },
					{ answerText: '$20', isCorrect: true },
					{ answerText: '$18', isCorrect: false },
					{ answerText: '$22', isCorrect: false },
				  ],
				},
			  
				// Statistics and Probability
				{
				  questionText: 'The following data represents the number of books read by students in a week: 2, 3, 4, 5, 3. What is the median number of books read?',
				  answerOptions: [
					{ answerText: '3', isCorrect: true },
					{ answerText: '6', isCorrect: false },
					{ answerText: '2', isCorrect: false },
					{ answerText: '5', isCorrect: false },
				  ],
				},
				{
				  questionText: 'The probability of rolling a 6 on a fair six-sided dice is 1/6. What is the probability of not rolling a 6?',
				  answerOptions: [
					{ answerText: '1/6', isCorrect: false },
					{ answerText: '1/3', isCorrect: false },
					{ answerText: '2/3', isCorrect: false },
					{ answerText: '5/6', isCorrect: true },
				  ],
				},
			  ];
			  



			  export default function Test() {
				const [currentQuestion, setCurrentQuestion] = useState(0);
				const [showScore, setShowScore] = useState(false);
				const [score, setScore] = useState(0);
				const [user, setUser] = useState(null);
  				const [gmas, setGmas] = useState(0);
  				const [highGmas, setHighGmas] = useState(0);

const getScoreAndGmas = useCallback(async () => {
    try {
      if (!user || !user.uid) {
        return; // Exit the function if user is null or user.uid is null
      }
      const userRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(userRef);


      if (docSnap.exists()) {
        // If the document exists, get the user's score
        const userData = docSnap.data();
        setGmas(userData.gmas);
        setHighGmas(userData.highgmas);
      } else {
        // If the document doesn't exist, create it with the initial score
        await setDoc(userRef, { gmas: 0, highgmas: 0 });
        setGmas(0);
        setHighGmas(0);
      }
    } catch (err) {
      console.error("Error getting user score:", err);
    }
  }, [user]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
	  setUser(user);
      getScoreAndGmas();
    });

    return () => unsubscribe();
  }, [getScoreAndGmas]);
			  
				const shuffledQuestions = useMemo(() => {
				  // Function to shuffle the questions array
				  const shuffleQuestions = () => {
					const shuffledQuestions = [...questions];
					for (let i = shuffledQuestions.length - 1; i > 0; i--) {
					  const j = Math.floor(Math.random() * (i + 1));
					  [shuffledQuestions[i], shuffledQuestions[j]] = [
						shuffledQuestions[j],
						shuffledQuestions[i],
					  ];
					}
					return shuffledQuestions;
				  };
			  
				  const result = shuffleQuestions();
				  console.log(result); // Add this line to log the shuffledQuestions
				  return result;

				}, []);

				useEffect(() => {
					// The effect logic here (if needed)
				  }, [shuffledQuestions]);
				
				  const updateUserScores = async (userRef, percentageCorrect) => {
					try {
					  const docSnap = await getDoc(userRef); // Fetch the user's document
					  const userData = docSnap.data();
				  
					  if (userData.highgmas === undefined || parseFloat(percentageCorrect) > userData.highgmas) {
						// If highgmas field doesn't exist or the new percentageCorrect is higher
						let newData = {
						  gmas: parseFloat(percentageCorrect),
						};
				  
						if (userData.highgmas === undefined) {
							// If highgmas field doesn't exist, set it to the same value as gmas
							newData = { ...newData, highgmas: parseFloat(percentageCorrect) };
						  } else if (parseFloat(percentageCorrect) > userData.highgmas) {
							// Otherwise, update the existing highgmas value
							newData = { ...newData, highgmas: parseFloat(percentageCorrect) };
						  }
				  
						await updateDoc(userRef, newData);
					  } else {
						// Update only the gmas value
						await updateDoc(userRef, { gmas: parseFloat(percentageCorrect) });
					  }
					} catch (error) {
					  console.error("Error fetching/updating user document:", error);
					}
				  };
				
				  const handleAnswerOptionClick = async (isCorrect) => {
					console.log("Answer clicked");
					if (isCorrect) {
					  setScore(score + 1);
					}
				
					const nextQuestion = currentQuestion + 1;
				
					if (nextQuestion < shuffledQuestions.length) {
					  setCurrentQuestion(nextQuestion);
					} else {
					  setShowScore(true);
					  const percentageCorrect = ((score / shuffledQuestions.length) * 100).toFixed(1); // Limit to one decimal place
					  const user = auth.currentUser;
					  if (user) {
						const userRef = doc(db, "users", user.uid);
						await updateUserScores(userRef, percentageCorrect);
					  }
					}
				  };
				
				  return (
					<div className='entireT'>
					  <div style={{ textAlign: 'center', marginBottom: '20px' }}>
						<h2>This test will not contain explanations for incorrect answers.</h2>
						<p>This test contains 30 questions based on the math section of the GMAS.</p>
					  </div>
					  <div className='Test'>
						{showScore ? ( // If showScore is true, display the score
						  <div className='score-section'>
							You scored {score} out of {shuffledQuestions.length}
							
						  </div>
						) : currentQuestion < shuffledQuestions.length ? ( // Display the question if there are more questions
						  <>
							<div className='question-section'>
							  <div className='question-count'>
								<span>Question {currentQuestion + 1}</span>/{shuffledQuestions.length}
							  </div>
							  <div className='question-text'>
								{shuffledQuestions[currentQuestion].questionText}
							  </div>
							</div>
							<div className='answer-section'>
							  {shuffledQuestions[currentQuestion].answerOptions.map((answerOption, index) => (
								<button
								  key={index}
								  onClick={() => handleAnswerOptionClick(answerOption.isCorrect)}
								>
								  {answerOption.answerText}
								</button>
							  ))}
							</div>
						  </>
						) : null} {/* If there are no more questions and showScore is false, display nothing */}
					  </div>
					  <br></br>
					  <div className="right-cardt">
              <h1 className="small-text">Best Gmas Score: <br /><center>{highGmas}%</center></h1>
              <h1 className="small-text">Recent Gmas Score: <br /><center>{gmas}%</center></h1>
            </div>
					</div>
					
				  );
							  }