import React, { useEffect, useState, useCallback } from 'react';
import { db } from './firebase';
import { collection, query, orderBy, getDocs, limit, updateDoc, getDoc, setDoc, doc} from 'firebase/firestore';
import { auth } from "./firebase";
import { Link } from 'react-router-dom';
import badgeImage from './img/badgeone.png';
import badgetwoImage from './img/badgetwo.png';
import badgethreeImage from './img/badgethree.png';
import pngegg from './img/pngegg.png';
import './leaderboard.css';

function Leaderboard() {
  const [inputValueone, setInputValueone] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [leaderboardItems, setLeaderboardItems] = useState([]);
  const [gotOneButtonClicked, setGotOneButtonClicked] = useState(false);
  const [gotTwoButtonClicked, setGotTwoButtonClicked] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [inputValue2, setInputValue2] = useState('');
  const [inputValue3, setInputValue3] = useState('');
  const [gotOneClicked, setGotOneClicked] = useState(false);
  const [gotTwoClicked, setGotTwoClicked] = useState(false);
  const [user, setUser] = useState(null);
  const [hasBadgeOne, setHasBadgeOne] = useState(false);
  const [hasBadgeTwo, setHasBadgeTwo] = useState(false);
  const [hasBadgeThree, setHasBadgeThree] = useState(false);
  				const [score, setScore] = useState(0);
  				const [clname, setClname] = useState(null);

const getScoreAndGmas = useCallback(async () => {
    try {
      if (!user || !user.uid) {
        return; // Exit the function if user is null or user.uid is null
      }
      const userRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(userRef);


      if (docSnap.exists()) {
        // If the document exists, get the user's score
        const userData = docSnap.data();
        setScore(userData.score);
        setClname(userData.clname);
        const badgeOneValue = userData.badgeone;
        const badgeTwoValue = userData.badgetwo;
        const badgeThreeValue = userData.badgethree;
        if (badgeOneValue === 1) {
          setHasBadgeOne(true); // Update badge state based on Firestore value
        }
        if (badgeTwoValue === 1) {
          setHasBadgeTwo(true); // Update badge state based on Firestore value
        }
        if (badgeThreeValue === 1) {
          setHasBadgeThree(true); // Update badge state based on Firestore value
        }

      } 
    } catch (err) {
      console.error("Error getting user score:", err);
    }
  }, [user]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
	  setUser(user);
      getScoreAndGmas();
    });

    return () => unsubscribe();
  }, [getScoreAndGmas]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(collection(db, 'users'), orderBy('score', 'desc'), limit(10));
        const querySnapshot = await getDocs(q);

        const leaderboardData = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          leaderboardData.push(data);
        });

        setLeaderboardItems(leaderboardData);

      } catch (error) {
        console.error('Error fetching leaderboard data:', error);
      }
    };

    fetchData();
  }, []);

  const handleInputChangeone = (event) => {
    const newValue = event.target.value;
    setInputValueone(newValue);
    setButtonDisabled(newValue.length < 3 || newValue.length > 8);
  };

  const handleAddCLName = async () => {
    try {
      // Get the currently logged in user
      const user = auth.currentUser;

      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          if (!userData.clname) {
            
            await updateDoc(userRef, { clname: inputValueone });
          }
        }
      }
    } catch (error) {
      console.error('Error adding clname:', error);
    }
  }
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setUser(user);
      if (user) {
        getScoreAndGmas();
  
        // Get the name from localStorage
        const nameFromLocalStorage = localStorage.getItem("name");
  
        if (nameFromLocalStorage) {
          try {
            const userRef = doc(db, "users", user.uid);
            const docSnap = await getDoc(userRef);
  
            if (docSnap.exists()) {
              const userData = docSnap.data();
              if (!userData.hasOwnProperty("name")) {
                // If "name" field doesn't exist, set it with the value from local storage
                await updateDoc(userRef, {
                  name: nameFromLocalStorage,
                });
              }
            }
          } catch (err) {
            console.error("Error updating user's name:", err);
          }
        }
      }
  
      return () => unsubscribe();
    }, []);

  if (score === 1) {
    setInputValue('completed');
  }

  if (score === 2) {
    setInputValue2('completed');
  }

  if (score === 3) {
    setInputValue3('completed');
  }

  return () => unsubscribe();
}, [getScoreAndGmas, score]);


const handleGotOneButtonClick = () => {
  setGotOneButtonClicked(true); // Mark "Got One" button as clicked
  setGotOneClicked(true);
};

const handleGotTwoButtonClick = () => {
  setGotOneButtonClicked(true); // Mark "Got One" button as clicked
  setGotOneClicked(true);
  setGotTwoButtonClicked(true); // Mark "Got One" button as clicked
  setGotTwoClicked(true);
};



const handleScoreIncrement = async () => {
  try {
    console.log('handleScoreIncrement called');
    
    if (!user) {
      console.log('No user found');
      return;
    }

    const userRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(userRef);
    const userData = docSnap.data();

    const newScore = (userData && userData.score) ? userData.score + 1 : 1;

  // Update the score in the database
  await setDoc(userRef, { score: newScore }, { merge: true });

  setScore(newScore);



    // Check if the new score is 1 and update the inputValue state
    if (newScore === 1) {
      setInputValue('completed');
      console.log('inputValue set to completed');
    }

    if (newScore === 2) {
      setInputValue2('completed');
      console.log('inputValue2 set to completed');
    }

    if (newScore === 3) {
      setInputValue3('completed');
      console.log('inputValue3 set to completed');
    }

    // Update the local state with the new score
    setScore(newScore);
    console.log('Score updated');
  } catch (error) {
    console.error("Error updating score:", error);
  }
};

const handleInputChange = (event) => {
  setInputValue(event.target.value);
};

const handleInputChange2 = (event) => {
  setInputValue2(event.target.value);
};

const handleInputChange3 = (event) => {
  setInputValue3(event.target.value);
};

const scoreCardContent = (
  <div>
    <b>Leet Questions:</b><br />
    <b>Evaluate the<br /> expression 2^3.</b><br />
    {inputValue !== 'completed' ? (
      <input
        type="number"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Type the answer"
      />
    ) : (
      <p>Completed</p>
    )}
  </div>
);


const scoreCardContent2 = (
  <div>
    <b>Leet Questions:</b><br />
    <b>Solve for x<br />in 4x - 1 = 7</b><br />
    {inputValue2 !== 'completed' ? (
      <input
        type="number"
        value={inputValue2}
        onChange={handleInputChange2}
        placeholder="Type the answer"
      />
    ) : (
      <p>Completed</p>
    )}
  </div>
);

const scoreCardContent3 = (
  <div>
    <b>Leet Questions:</b><br />
    <b>Solve for y<br />in y - 2 = 7 + 2</b><br />
    {inputValue3 !== 'completed' ? (
      <input
        type="number"
        value={inputValue3}
        onChange={handleInputChange3}
        placeholder="Type the answer"
      />
    ) : (
      <p>Completed</p>
    )}
  </div>
)
  ;

  return (
    <div>
    <div>
    <div className="leaderboard"> 
    <center><div className='tpart'>
              <h1 className="small-text">My Leet Points: <br /><center>{score}</center></h1>
              <h1 className="small-text">Leaderboard Name: <br /><center>{clname}</center></h1>
              </div></center>
      <h2>Top 10 Leet Points:</h2>
      <table className="leaderboard-table">
        <thead>
          <tr>
            <th>Rank</th>
            <th>Name</th>
            <th>Leet Points</th>
            <th>Badges</th>
          </tr>
        </thead>
        

<tbody>
  
  {leaderboardItems.map((user, index) => (
    <tr key={user.uid}>
      <td>
        {index === 0 ? (
          <img
            src={pngegg}
            style={{ width: '34px', height: '34px', margin: '10px auto' }}
            alt={`Rank 1`}
          />
        ) : (
          index + 1
        )}
      </td>
              <td>
                {user.clname ? user.clname : user.lbname}
              </td>
              <td>{user.score}</td>
              <td className="badges-column">
                {user.badgeone === 1 && <img src={badgeImage} style={{ width: '50px', height: '50px', margin: '10px auto' }} alt="Badge One" />}
                {user.badgetwo === 1 && <img src={badgetwoImage} style={{ width: '50px', height: '50px', margin: '10px auto' }} alt="Badge Two" />}
                {user.badgethree === 1 && <img src={badgethreeImage} style={{ width: '50px', height: '50px', margin: '10px auto' }} alt="Badge Three" />}
              </td>
            </tr>
            
          ))}
        </tbody>
      </table>




      
      <center><br></br>You can change your name from "anonymous" by submiting the new name below. You will only be able to change your name once every month.<br></br></center>
      <div className="input-container">
        <input
          type="text"
          placeholder="Enter your new name"
          value={inputValueone}
          onChange={handleInputChangeone}
          className="input-field"
        />
        <button
          onClick={handleAddCLName}
          disabled={buttonDisabled}
          className="add-button"
        >
          Change Leaderboard Name
        </button>
      </div>
      <br></br>
      </div>
      <div className="page-contenttwo">
  {user && (
    <>
      <center><div className="score-cardsmall">
      {score === 3 && inputValue3 !== 'completed'
      ? scoreCardContent3
      : (gotTwoClicked || (score === 3))
    ? scoreCardContent3
    : (gotOneClicked || (score === 2 && inputValue3 !== 'completed'))
    ? scoreCardContent2
    : score === 2
    ? scoreCardContent2
    : scoreCardContent}
        <div className="user-infotwo">
          {inputValue === '8' && (
            <div className="increase-containersmall">
              <button onClick={handleScoreIncrement}>Increase Score</button>
            </div>
          )}
          {inputValue2 === '2' && (
            <div className="increase-containersmall">
              <button onClick={handleScoreIncrement}>Increase Score</button>
            </div>
          )}
          {inputValue3 === '11' && (
            <div className="increase-containersmall">
              <button onClick={handleScoreIncrement}>Increase Score</button>
            </div>
          )}
          {score === 1 && !gotOneButtonClicked && (
            <div className="increase-containersmall">
              <button className="black-button" onClick={handleGotOneButtonClick}>next</button>
            </div>
          )}
          {score === 2 && !gotTwoButtonClicked && (
            <div className="increase-containersmall">
              <button className="black-button" onClick={handleGotTwoButtonClick}>next</button>
            </div>
          )}
        
        </div>
        </div></center>
    </>
    )}
    <br></br>
      </div>
      <br></br>
      <center><div className="badgess">
          <p>My Badges:</p>
          {hasBadgeOne && ( // Conditionally render badge image
            <img
              src={badgeImage}
              alt="Badge One"
              style={{ width: '50px', height: '50px', margin: '10px auto' }}
            />
          )}
          {hasBadgeTwo && ( // Conditionally render badge image
            <img
              src={badgetwoImage}
              alt="Badge Two"
              style={{ width: '50px', height: '50px', margin: '10px auto' }}
            />
          )}
          {hasBadgeThree && ( // Conditionally render badge image
            <img
              src={badgethreeImage}
              alt="Badge Three"
              style={{ width: '50px', height: '50px', margin: '10px auto' }}
            />
          )}
        </div></center></div>

        <br></br><br></br>
<div>2023 Copyright Georgia Leet Math <Link to="/Privacypolicya">Privacy Policy</Link></div>
    </div>


      

    
    
  );
}

export default Leaderboard;