import React from 'react';
import { Link } from 'react-router-dom';
import './CExpression.css'; // Import your CSS file for styling

function CAlgebra() {
  return (
    <div className="card">
      <Link to="/CAa" className="button-link">
        <button className="button">
          Problem 1
          <span className="checkbox"></span>
        </button>
      </Link>
      <Link to="/CAb" className="button-link">
        <button className="button">
          Problem 2
          <span className="checkbox"></span>
        </button>
      </Link>
      <Link to="/CAc" className="button-link">
        <button className="button">
          Problem 3
          <span className="checkbox"></span>
        </button>
      </Link>
      <Link to="/CAd" className="button-link">
        <button className="button">
          Problem 4
          <span className="checkbox"></span>
        </button>
      </Link>
      <Link to="/CAe" className="button-link">
        <button className="button">
          Problem 5
          <span className="checkbox"></span>
        </button>
      </Link>
    </div>
  );
}

export default CAlgebra;
