import React, { useEffect, useState, useCallback } from 'react';
import Navbar from "./navbar";
import Test from "./pages/Test";
import Home from "./pages/Home";
import Algebra from "./pages/Algebra";
import NumberOper from "./pages/NumberOper";
import Geometry from "./pages/Geometry";
import Dataprob from "./pages/Dataprob";
import About from "./pages/About";
import FunctionsTutorial from './FunctionsTutorial';
import { Routes, Route } from 'react-router-dom';
import SlopeTutorial from './SlopeTutorial';
import CExpressions from './pages/CExpressions';
import CAlgebra from './pages/CAlgebra';
import Privacypolicya from './pages/Privacypolicya';
import CEa from './pages/CEa';
import CEb from './pages/CEb';
import CGa from './pages/CGa';
import CGb from './pages/CGb';
import CGc from './pages/CGc';
import CETa from './pages/CETa';
import CETb from './pages/CETb';
import CETc from './pages/CETc';
import CGeometry from './pages/CGeometry';
import CExponents from './pages/CExponents';
import RatiosTutorial from './RatiosTutorial';
import AreaVolumeTutorial from './AreaVolumeTutorial';
import ProbabilityTutorial from './ProbabillityTutorial';
import StatisticsTutorial from './StatisticsTutorial';
import TransformationsTutorial from './TransformationsTutorial';
import ScientificNotationTutorial from './ScientificNotationTutorial';
import {
  signOut,
} from "firebase/auth";
import './App.css';
import { auth } from "./firebase";
import "./Navbar.css";
import { db } from "./firebase";
import {
  getDoc,
  setDoc,
  updateDoc,
  doc
} from "firebase/firestore";
import { signInWithGoogle } from './firebaseUtils';
import gglepngweb from './img/gglepngweb.png';
import {Helmet} from "react-helmet";
import badgeImage from './img/badgeone.png';
import badgetwoImage from './img/badgetwo.png';
import badgethreeImage from './img/badgethree.png';
import Leaderboard from './leaderboard';

function App() {
  const [user, setUser] = useState(null);
  const [score, setScore] = useState(0);
  const [gmas, setGmas] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [inputValue2, setInputValue2] = useState('');
  const [inputValue3, setInputValue3] = useState('');
  const [highGmas, setHighGmas] = useState(0);
  const [gotOneButtonClicked, setGotOneButtonClicked] = useState(false);
  const [gotTwoButtonClicked, setGotTwoButtonClicked] = useState(false);
  const [gotOneClicked, setGotOneClicked] = useState(false);
  const [gotTwoClicked, setGotTwoClicked] = useState(false);
  const [hasBadgeOne, setHasBadgeOne] = useState(false);
  const [hasBadgeTwo, setHasBadgeTwo] = useState(false);
  const [hasBadgeThree, setHasBadgeThree] = useState(false);
  const [, setLbName] = useState('');

    useEffect(() => {
      if (window.google) {
        const googleId = window.google.accounts.id;
  
        const handleCredentialResponse = (response) => {
          const credential = response.credential;
          if (credential) {
            // User signed in successfully
            console.log('User signed in:', credential);
          } else {
            // User did not sign in
            console.log('User did not sign in.');
          }
        };
  
        const googleSignInOptions = {
          client_id: '349429667404-1i7soh3uvqbp0rhgl78k669okbn4mvdu.apps.googleusercontent.com',
        };
  
        googleId.initialize(googleSignInOptions);
        googleId.prompt(handleCredentialResponse);
      }
    }, []);

  
  

  
  const getScoreAndGmas = useCallback(async () => {
    try {
      if (!user || !user.uid) {
        return; // Exit the function if user is null or user.uid is null
      }
      const userRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(userRef);
  
      if (docSnap.exists()) {
        // If the document exists, get the user's score
        const userData = docSnap.data();
        setScore(userData.score);
        setGmas(userData.gmas);
        setHighGmas(userData.highgmas);
        setLbName(userData.lbname);

  
        // Check if the user has the badgeone
        const badgeOneValue = userData.badgeone;
        const badgeTwoValue = userData.badgetwo;
        const badgeThreeValue = userData.badgethree;
        if (badgeOneValue === 1) {
          setHasBadgeOne(true); // Update badge state based on Firestore value
        }
        if (badgeTwoValue === 1) {
          setHasBadgeTwo(true); // Update badge state based on Firestore value
        }
        if (badgeThreeValue === 1) {
          setHasBadgeThree(true); // Update badge state based on Firestore value
        }
      } else {
        // If the document doesn't exist, create it with the initial score
        await setDoc(userRef, { score: 0, gmas: 0, highgmas: 0, lbname: 'anonymous' });
        setScore(0);
        setGmas(0);
        setHighGmas(0);
        setLbName('anonymous');
      }
    } catch (err) {
      console.error("Error getting user score:", err);
    }
  }, [user]);
  

  

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setUser(user);
      if (user) {
        getScoreAndGmas();
  
        // Get the name from localStorage
        const nameFromLocalStorage = localStorage.getItem("name");
  
        if (nameFromLocalStorage) {
          try {
            const userRef = doc(db, "users", user.uid);
            const docSnap = await getDoc(userRef);
  
            if (docSnap.exists()) {
              const userData = docSnap.data();
              if (!userData.hasOwnProperty("name")) {
                // If "name" field doesn't exist, set it with the value from local storage
                await updateDoc(userRef, {
                  name: nameFromLocalStorage,
                });
              }
            }
          } catch (err) {
            console.error("Error updating user's name:", err);
          }
        }
      }
  
      return () => unsubscribe();
    }, []);
  

    if (score === 1) {
      setInputValue('completed');
    }

    if (score === 2) {
      setInputValue2('completed');
    }

    if (score === 3) {
      setInputValue3('completed');
    }

    return () => unsubscribe();
  }, [getScoreAndGmas, score]);
  

  const handleGotOneButtonClick = () => {
    setGotOneButtonClicked(true); // Mark "Got One" button as clicked
    setGotOneClicked(true);
  };

  const handleGotTwoButtonClick = () => {
    setGotOneButtonClicked(true); // Mark "Got One" button as clicked
    setGotOneClicked(true);
    setGotTwoButtonClicked(true); // Mark "Got One" button as clicked
    setGotTwoClicked(true);
  };



  const handleScoreIncrement = async () => {
    try {
      console.log('handleScoreIncrement called');
      
      if (!user) {
        console.log('No user found');
        return;
      }

      const userRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(userRef);
      const userData = docSnap.data();

      const newScore = (userData && userData.score) ? userData.score + 1 : 1;

    // Update the score in the database
    await setDoc(userRef, { score: newScore }, { merge: true });

    setScore(newScore);



      // Check if the new score is 1 and update the inputValue state
      if (newScore === 1) {
        setInputValue('completed');
        console.log('inputValue set to completed');
      }

      if (newScore === 2) {
        setInputValue2('completed');
        console.log('inputValue2 set to completed');
      }

      if (newScore === 3) {
        setInputValue3('completed');
        console.log('inputValue3 set to completed');
      }
  
      // Update the local state with the new score
      setScore(newScore);
      console.log('Score updated');
    } catch (error) {
      console.error("Error updating score:", error);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (err) {
      console.error(err);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputChange2 = (event) => {
    setInputValue2(event.target.value);
  };

  const handleInputChange3 = (event) => {
    setInputValue3(event.target.value);
  };

  const scoreCardContent = (
    <div>
      <b>Leet Questions:</b><br />
      <b>Evaluate the<br /> expression 2^3.</b><br />
      {inputValue !== 'completed' ? (
        <input
          type="number"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Type the answer"
        />
      ) : (
        <p>Completed</p>
      )}
    </div>
  );


  const scoreCardContent2 = (
    <div>
      <b>Leet Questions:</b><br />
      <b>Solve for x<br />in 4x - 1 = 7</b><br />
      {inputValue2 !== 'completed' ? (
        <input
          type="number"
          value={inputValue2}
          onChange={handleInputChange2}
          placeholder="Type the answer"
        />
      ) : (
        <p>Completed</p>
      )}
    </div>
  );

  const scoreCardContent3 = (
    <div>
      <b>Leet Questions:</b><br />
      <b>Solve for y<br />in y - 2 = 7 + 2</b><br />
      {inputValue3 !== 'completed' ? (
        <input
          type="number"
          value={inputValue3}
          onChange={handleInputChange3}
          placeholder="Type the answer"
        />
      ) : (
        <p>Completed</p>
      )}
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Leet Math</title>
      </Helmet>
      <Navbar />
      <div className="containers">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Test" element={<Test />} />
          <Route path="/NumberOper" element={<NumberOper />} />
          <Route path="/Geometry" element={<Geometry />} />
          <Route path="/Algebra" element={<Algebra />} />
          <Route path="/About" element={<About />} />
          <Route path="/Dataprob" element={<Dataprob />} />
          <Route path="/SlopeTutorial" element={<SlopeTutorial />} />
          <Route path="/RatiosTutorial" element={<RatiosTutorial />} />
          <Route path="/ProbabilityTutorial" element={<ProbabilityTutorial />} />
          <Route path="/FunctionsTutorial" element={<FunctionsTutorial />} />
          <Route path="/ScientificNotationTutorial" element={<ScientificNotationTutorial />} />
          <Route path="/AreaVolumeTutorial" element={<AreaVolumeTutorial />} />
          <Route path="/StatisticsTutorial" element={<StatisticsTutorial />} />
          <Route path="/TransformationsTutorial" element={<TransformationsTutorial />} />
          <Route path="/CExpressions" element={<CExpressions />} />
          <Route path="/CAlgebra" element={<CAlgebra />} />
          <Route path="/CExponents" element={<CExponents />} />
          <Route path="/CGeometry" element={<CGeometry />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/CEa" element={<CEa />} />
          <Route path="/CEb" element={<CEb />} />
          <Route path="/CGa" element={<CGa />} />
          <Route path="/CGb" element={<CGb />} />
          <Route path="/CGc" element={<CGc />} />
          <Route path="/CETa" element={<CETa />} />
          <Route path="/CETb" element={<CETb />} />
          <Route path="/CETc" element={<CETc />} />
          <Route path="/Privacypolicya" element={<Privacypolicya />} />
        </Routes>
      </div>
      
<div className="user-info">
  {user ? (
    <>
      <div className="user-info-container">
        <h1 className="small-text">Name: </h1>
        <h1 className="smaller-text">{localStorage.getItem("name")}</h1>
        <h1 className="small-text">Email: </h1>
        <h1 className="smallerr-text">{localStorage.getItem("email")}</h1>
        <h1 className="small-text">Leet Points: <br /><center>{score}</center></h1><br />
        <h1 className="smaller-text">
          <center><b>Solve Leet <br />Questions to increase<br /> your points.</b></center>
        </h1>
      </div>
    </>
  ) : (
    <button onClick={signInWithGoogle} className="login-button">
      <img src={gglepngweb} alt="Login" className="logout-image" />
      <span className="login-text">Sign In</span>
    </button>
  )}
</div>
<div className="page-content">
  {user && (
    <>
      <div className="right-card">
        <h1 className="small-text">Best Gmas Score: <br /><center>{highGmas}%</center></h1>
        <h1 className="small-text">Recent Gmas Score: <br /><center>{gmas}%</center></h1>
        <div className="badges">
          <p>Badges:</p>
          {hasBadgeOne && ( // Conditionally render badge image
            <img
              src={badgeImage}
              alt="Badge One"
              style={{ width: '50px', height: '50px', margin: '10px auto' }}
            />
          )}
          {hasBadgeTwo && ( // Conditionally render badge image
            <img
              src={badgetwoImage}
              alt="Badge Two"
              style={{ width: '50px', height: '50px', margin: '10px auto' }}
            />
          )}
          {hasBadgeThree && ( // Conditionally render badge image
            <img
              src={badgethreeImage}
              alt="Badge Three"
              style={{ width: '50px', height: '50px', margin: '10px auto' }}
            />
          )}
        </div>
      </div>
      <div className="score-card">
      {score === 3 && inputValue3 !== 'completed'
      ? scoreCardContent3
      : (gotTwoClicked || (score === 3))
    ? scoreCardContent3
    : (gotOneClicked || (score === 2 && inputValue3 !== 'completed'))
    ? scoreCardContent2
    : score === 2
    ? scoreCardContent2
    : scoreCardContent}
        <div className="user-info">
          {inputValue === '8' && (
            <div className="increase-container">
              <button onClick={handleScoreIncrement}>Increase Score</button>
            </div>
          )}
          {inputValue2 === '2' && (
            <div className="increase-container">
              <button onClick={handleScoreIncrement}>Increase Score</button>
            </div>
          )}
          {inputValue3 === '11' && (
            <div className="increase-container">
              <button onClick={handleScoreIncrement}>Increase Score</button>
            </div>
          )}
          {score === 1 && !gotOneButtonClicked && (
            <div className="increase-container">
              <button className="black-button" onClick={handleGotOneButtonClick}>next</button>
            </div>
          )}
          {score === 2 && !gotTwoButtonClicked && (
            <div className="increase-container">
              <button className="black-button" onClick={handleGotTwoButtonClick}>next</button>
            </div>
          )}

        </div>
      </div>
    </>
  )}
</div>
{user && (
  <div className="logout-container">
    <button onClick={logout} className="logout-button">
      <div className="logout-content">
        <img src={gglepngweb} alt="Logout" className="logout-image" />
        <span className="logout-text">Logout</span>
      </div>
    </button>
  </div>
)}
    </>
  );
  
}

export default App;