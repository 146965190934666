import React, { useState, useEffect, useMemo } from 'react';
import './Test.css';
import './Popup.css';
import '../App.css';
import Incorrectp from './Incorrectp';

  const questions = [
    {
      incorrectAnswerMessage: 'The value of x in the equation 3x + 5 = 14 can be found by subtracting 5 from both sides, then dividing by 3. Thus, x = (14 - 5) / 3 = 3.',
      questionText: 'Solve for x: 3x + 5 = 14',
      answerOptions: [
        { answerText: 'x = 3', isCorrect: true },
        { answerText: 'x = 7', isCorrect: false },
        { answerText: 'x = 5', isCorrect: false },
        { answerText: 'x = 9', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'To solve for x, first subtract 8 from both sides and then divide by -2. Thus, x = (16 - 8) / -2 = -4.',
      questionText: 'Solve for x: -2x - 8 = 16',
      answerOptions: [
        { answerText: 'x = -4', isCorrect: true },
        { answerText: 'x = 6', isCorrect: false },
        { answerText: 'x = -8', isCorrect: false },
        { answerText: 'x = 4', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'To simplify the expression, distribute the 3 to both terms inside the parentheses. Thus, 3(2x + 5) = 6x + 15.',
      questionText: 'Simplify the expression: 3(2x + 5)',
      answerOptions: [
        { answerText: '6x + 5', isCorrect: false },
        { answerText: '6x + 15', isCorrect: true },
        { answerText: '2x + 5', isCorrect: false },
        { answerText: '5x + 3', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The equation can be solved by isolating the variable on one side. Add 7 to both sides, then divide by 3. Thus, x = (10 + 7) / 3 = 17 / 3.',
      questionText: 'Solve for x: 3x - 7 = 10',
      answerOptions: [
        { answerText: 'x = 3', isCorrect: false },
        { answerText: 'x = 17/3', isCorrect: true },
        { answerText: 'x = 7', isCorrect: false },
        { answerText: 'x = 5', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The expression can be simplified by combining like terms. The terms 5y and -3y are like terms, so 5y - 3y = 2y.',
      questionText: 'Simplify the expression: 5y - 3y',
      answerOptions: [
        { answerText: '8y', isCorrect: false },
        { answerText: '2y', isCorrect: true },
        { answerText: '15y', isCorrect: false },
        { answerText: '3y', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'To solve for x, subtract 6 from both sides and then divide by 2. Thus, x = (18 - 6) / 2 = 12 / 2 = 6.',
      questionText: 'Solve for x: 2x + 6 = 18',
      answerOptions: [
        { answerText: 'x = 6', isCorrect: true },
        { answerText: 'x = 5', isCorrect: false },
        { answerText: 'x = 8', isCorrect: false },
        { answerText: 'x = 9', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The expression can be simplified by adding the coefficients of like terms. Like terms have the same variable raised to the same power. In this case, 7a and -3a are like terms, so 7a - 3a = 4a.',
      questionText: 'Simplify the expression: 7a - 3a',
      answerOptions: [
        { answerText: '10a', isCorrect: false },
        { answerText: '4a', isCorrect: true },
        { answerText: '6a', isCorrect: false },
        { answerText: '3a', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'To solve for x, subtract 3 from both sides and then divide by -5. Remember that dividing by a negative number changes the direction of the inequality. Thus, x = (6 - 3) / -5 = 3 / -5.',
      questionText: 'Solve for x: -5x + 3 < 6',
      answerOptions: [
        { answerText: 'x = -1', isCorrect: false },
        { answerText: 'x = 1', isCorrect: false },
        { answerText: 'x = 3/5', isCorrect: true },
        { answerText: 'x = -3/5', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The expression can be simplified by distributing the negative sign to both terms inside the parentheses. Thus, -1(4x - 2) = -4x + 2.',
      questionText: 'Simplify the expression: -1(4x - 2)',
      answerOptions: [
        { answerText: '-4x + 2', isCorrect: true },
        { answerText: '4x - 2', isCorrect: false },
        { answerText: '-4x - 2', isCorrect: false },
        { answerText: '2 - 4x', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'To solve for x, divide both sides by 4. Thus, x = 16 / 4 = 4.',
      questionText: 'Solve for x: 4x = 16',
      answerOptions: [
        { answerText: 'x = 4', isCorrect: true },
        { answerText: 'x = 6', isCorrect: false },
        { answerText: 'x = 8', isCorrect: false },
        { answerText: 'x = 10', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The expression can be simplified by distributing the 2 to both terms inside the parentheses. Thus, 2(3x + 5) = 6x + 10.',
      questionText: 'Simplify the expression: 2(3x + 5)',
      answerOptions: [
        { answerText: '6x + 5', isCorrect: false },
        { answerText: '2x + 10', isCorrect: false },
        { answerText: '3x + 5', isCorrect: false },
        { answerText: '6x + 10', isCorrect: true },
      ],
    },
    {
      incorrectAnswerMessage: 'To solve for x, subtract 8 from both sides and then divide by -3. Remember that dividing by a negative number changes the direction of the inequality. Thus, x = (8 - 8) / -3 = 0.',
      questionText: 'Solve for x: -3x + 8 > 0',
      answerOptions: [
        { answerText: 'x = 2', isCorrect: false },
        { answerText: 'x = 4', isCorrect: false },
        { answerText: 'x = 0', isCorrect: true },
        { answerText: 'x = -2', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The expression can be simplified by combining like terms. Like terms have the same variable raised to the same power. In this case, 3x and -2x are like terms, so 3x - 2x = x.',
      questionText: 'Simplify the expression: 3x - 2x',
      answerOptions: [
        { answerText: '5x', isCorrect: false },
        { answerText: 'x', isCorrect: true },
        { answerText: 'x^2', isCorrect: false },
        { answerText: '-x', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'To solve for x, divide both sides by 2. Thus, x = 12 / 2 = 6.',
      questionText: 'Solve for x: 2x = 12',
      answerOptions: [
        { answerText: 'x = 4', isCorrect: false },
        { answerText: 'x = 6', isCorrect: true },
        { answerText: 'x = 8', isCorrect: false },
        { answerText: 'x = 10', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The expression can be simplified by distributing the -3 to both terms inside the parentheses. Thus, -3(2x - 7) = -6x + 21.',
      questionText: 'Simplify the expression: -3(2x - 7)',
      answerOptions: [
        { answerText: '-6x + 7', isCorrect: false },
        { answerText: '-6x - 7', isCorrect: false },
        { answerText: '-6x + 14', isCorrect: false },
        { answerText: '-6x + 21', isCorrect: true },
      ],
    },
    {
      incorrectAnswerMessage: 'To solve for x, subtract 5 from both sides and then divide by 4. Thus, x = (16 - 5) / 4 = 11 / 4.',
      questionText: 'Solve for x: 4x - 5 = 16',
      answerOptions: [
        { answerText: 'x = 2', isCorrect: false },
        { answerText: 'x = 3', isCorrect: false },
        { answerText: 'x = 11/4', isCorrect: true },
        { answerText: 'x = 5', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The expression can be simplified by combining like terms. Like terms have the same variable raised to the same power. In this case, 7x and -3x are like terms, so 7x - 3x = 4x.',
      questionText: 'Simplify the expression: 7x - 3x',
      answerOptions: [
        { answerText: '10x', isCorrect: false },
        { answerText: '4x', isCorrect: true },
        { answerText: 'x', isCorrect: false },
        { answerText: '7x', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'To solve for x, divide both sides by 3. Thus, x = 15 / 3 = 5.',
      questionText: 'Solve for x: 3x = 15',
      answerOptions: [
        { answerText: 'x = 3', isCorrect: false },
        { answerText: 'x = 5', isCorrect: true },
        { answerText: 'x = 7', isCorrect: false },
        { answerText: 'x = 10', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The expression can be simplified by combining like terms. Like terms have the same variable raised to the same power. In this case, 2a and 5a are like terms, so 2a + 5a = 7a.',
      questionText: 'Simplify the expression: 2a + 5a',
      answerOptions: [
        { answerText: '7a', isCorrect: true },
        { answerText: '10a', isCorrect: false },
        { answerText: '15a', isCorrect: false },
        { answerText: '2a + 5a', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'To solve for x, add 8 to both sides and then divide by 4. Thus, x = (20 + 8) / 4 = 28 / 4 = 7.',
      questionText: 'Solve for x: 4x - 8 = 20',
      answerOptions: [
        { answerText: 'x = 5', isCorrect: false },
        { answerText: 'x = 6', isCorrect: false },
        { answerText: 'x = 7', isCorrect: true },
        { answerText: 'x = 8', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The expression can be simplified by distributing the 2 to both terms inside the parentheses. Thus, 2(3x + 4) = 6x + 8.',
      questionText: 'Simplify the expression: 2(3x + 4)',
      answerOptions: [
        { answerText: '6x + 4', isCorrect: false },
        { answerText: '6x + 8', isCorrect: true },
        { answerText: '2x + 4', isCorrect: false },
        { answerText: '3x + 4', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'To solve for x, add 12 to both sides and then divide by 2. Thus, x = (18 + 12) / 2 = 30 / 2 = 15.',
      questionText: 'Solve for x: 2x - 12 = 18',
      answerOptions: [
        { answerText: 'x = 10', isCorrect: false },
        { answerText: 'x = 12', isCorrect: false },
        { answerText: 'x = 15', isCorrect: true },
        { answerText: 'x = 20', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The expression can be simplified by combining like terms. Like terms have the same variable raised to the same power. In this case, 3x and -2x are like terms, so 3x - 2x = x.',
      questionText: 'Simplify the expression: 3x - 2x',
      answerOptions: [
        { answerText: '5x', isCorrect: false },
        { answerText: 'x', isCorrect: true },
        { answerText: '-x', isCorrect: false },
        { answerText: '3x - 2x', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'To solve for x, add 9 to both sides and then divide by 5. Thus, x = (14 + 9) / 5 = 23 / 5.',
      questionText: 'Solve for x: 5x - 9 = 14',
      answerOptions: [
        { answerText: 'x = 3', isCorrect: false },
        { answerText: 'x = 4', isCorrect: false },
        { answerText: 'x = 23/5', isCorrect: true },
        { answerText: 'x = 6', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'To solve for x, add 7 to both sides and then divide by 6. Thus, x = (14 + 7) / 6 = 21 / 6 = 3.5.',
      questionText: 'Solve for x: 6x - 7 = 14',
      answerOptions: [
        { answerText: 'x = 2', isCorrect: false },
        { answerText: 'x = 3', isCorrect: false },
        { answerText: 'x = 3.5', isCorrect: true },
        { answerText: 'x = 4', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'To solve for x, divide both sides by -3. Remember that dividing by a negative number changes the direction of the inequality. Thus, x = 15 / -3 = -5.',
      questionText: 'Solve for x: -3x > -15',
      answerOptions: [
        { answerText: 'x = 5', isCorrect: false },
        { answerText: 'x = -3', isCorrect: false },
        { answerText: 'x = -5', isCorrect: true },
        { answerText: 'x = 3', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The equation can be solved by isolating the variable on one side. First, subtract 9 from both sides, then divide by 4. Thus, x = (20 - 9) / 4 = 11 / 4.',
      questionText: 'Solve for x: 4x + 9 = 20',
      answerOptions: [
        { answerText: 'x = 4', isCorrect: false },
        { answerText: 'x = 5', isCorrect: false },
        { answerText: 'x = 11/4', isCorrect: true },
        { answerText: 'x = 7', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The expression 4a + 2a can be simplified by combining like terms. Like terms have the same variable raised to the same power. In this case, 4a and 2a are like terms, so 4a + 2a = 6a.',
      questionText: 'Simplify the expression: 4a + 2a',
      answerOptions: [
        { answerText: '6a', isCorrect: true },
        { answerText: '2a', isCorrect: false },
        { answerText: '8a', isCorrect: false },
        { answerText: '12a', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The equation 2x + 7 = 3x - 1 can be solved by isolating the variable x. Move the term with x to one side of the equation, and the constant term to the other side. In this case, x = 8.',
      questionText: 'Solve for x: 2x + 7 = 3x - 1',
      answerOptions: [
        { answerText: 'x = 8', isCorrect: true },
        { answerText: 'x = 6', isCorrect: false },
        { answerText: 'x = 10', isCorrect: false },
        { answerText: 'x = 5', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The expression 3(x + 2) can be simplified by distributing the 3 to both terms inside the parentheses. In this case, 3(x + 2) = 3x + 6.',
      questionText: 'Simplify the expression: 3(x + 2)',
      answerOptions: [
        { answerText: '3x + 6', isCorrect: true },
        { answerText: '3x + 2', isCorrect: false },
        { answerText: 'x + 6', isCorrect: false },
        { answerText: 'x + 2', isCorrect: false },
      ],
    },
  ];


  export default function Algebra() {

  const [currentQuestion, setCurrentQuestion] = useState(0);
	const [showScore, setShowScore] = useState(false);
	const [score, setScore] = useState(0);
	const [showPopup, setShowPopup] = useState(false);
	const [popupMessage, setPopupMessage] = useState('');

	const shuffledQuestions = useMemo(() => {
		// Function to shuffle the questions array
		const shuffleQuestions = () => {
		  const shuffledQuestions = [...questions];
		  for (let i = shuffledQuestions.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[shuffledQuestions[i], shuffledQuestions[j]] = [
			  shuffledQuestions[j],
			  shuffledQuestions[i],
			];
		  }
		  return shuffledQuestions;
		};
	
		const result = shuffleQuestions();
		console.log(result); // Add this line to log the shuffledQuestions
		return result;

	  }, []);

	  useEffect(() => {
		// The effect logic here (if needed)
	  }, [shuffledQuestions]);

	const handleAnswerOptionClick = (isCorrect, incorrectAnswerMessage) => {
		if (isCorrect) {
		  setScore(score + 1);
		} else {
		  setShowPopup(true);
		  setPopupMessage(incorrectAnswerMessage);
		}
	  
		const nextQuestion = currentQuestion + 1;
		if (nextQuestion < questions.length) {
		  setCurrentQuestion(nextQuestion);
		} else {
		  setShowScore(true);
		}
	  };
	  
	
	  const handleClosePopup = () => {
		// Close the pop-up and proceed to the next question
		setShowPopup(false);
		setCurrentQuestion(currentQuestion);
		setPopupMessage('');
	  };
	
	  return (
		<div className='entireT'>
		  <div style={{ textAlign: 'center', marginBottom: '20px' }}>
			<h2>Algebra Math Practice</h2>
      Take notes for incorrect answers.
		  </div>
		  <div className='Test'>
			{showScore ? (
			  <div className='score-section'>
				You scored {score} out of {shuffledQuestions.length}
			  </div>
			) : currentQuestion < shuffledQuestions.length ? (
			  <>
				<div className='question-section'>
				  <div className='question-count'>
					<span>Question {currentQuestion + 1}</span>/{shuffledQuestions.length}
				  </div>
				  <div className='question-text'>
					{shuffledQuestions[currentQuestion].questionText}
				  </div>
				</div>
				
				<div className='answer-section'>
				  {shuffledQuestions[currentQuestion].answerOptions.map((answerOption) => (
					<button
					  key={answerOption.answerText}
					  onClick={() =>
						handleAnswerOptionClick(
						  answerOption.isCorrect,
						  shuffledQuestions[currentQuestion].incorrectAnswerMessage
						)
					  }
					>
					  {answerOption.answerText}
					</button>
				  ))}
				</div>
			  </>
			) : null}
			
			{showPopup && (
			  <div className='popup-card'>
				<Incorrectp message={popupMessage} onClose={handleClosePopup} />
			  </div>
			)}
		  </div>
		</div>
	  );
			}