import React from 'react';

const Incorrectp = ({ message, onClose }) => {
    return (
      <div className="popup">
        <p>{message}</p>
        <button onClick={onClose}>Close</button>
      </div>
  );
};

export default Incorrectp;