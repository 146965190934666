import React from 'react';
import './StatisticsTutorial.css'; // Optional CSS file for styling

const StatisticsTutorial = () => {
  return (
    <div className="statistics-tutorial">
      <h1>Statistics Tutorial</h1>
      {/* Add content for Statistics tutorial here */}
      <p><strong>Introduction to Statistics:</strong></p>
      <p>Statistics is a powerful mathematical tool that helps us make sense of data in various fields. It's like a detective kit for numbers, allowing us to uncover patterns, draw conclusions, and understand the world around us. Think of statistics as a magnifying glass that reveals hidden insights within data.</p>
      <p>Imagine you're a coach analyzing your basketball team's performance. By examining players' shooting percentages, rebounds, and assists, you can make informed decisions to enhance their skills. In middle school math, you'll dive into statistics to become a data detective!</p>
      <p>Statistics empowers us to extract valuable information from raw data, guiding our decisions and enabling us to explore questions that span from science to social studies.</p>
      
      <p><strong>Measures of Center:</strong></p>
<p>Imagine you're at a party, and you want to know where most of the guests are standing. Are they near the snacks, the dance floor, or the entrance? Measures of center help you answer similar questions about data, revealing where most of the values are concentrated.</p>
<p>Think of measures of center as your "average buddies" who give you a sense of what's typical or representative in a dataset. Let's meet these buddies:</p>
<ul>
  <li><strong>Mean (Average):</strong> The mean is like the ultimate party host who brings everyone together. To find the mean, you add up all the values in your dataset and then divide by the total number of values. It's like finding the center of gravity for your data. Imagine you're trying to find the average height of a group of friends. You'd add up their heights and then divide by the number of friends.</li>
  <li><strong>Median:</strong> The median is the wise old friend who lives right in the middle of the party. When your data is arranged from smallest to largest, the median is the value that sits at the exact center. If you have an odd number of values, it's like the person in the middle of a row of dancers. But if you have an even number of values, it's like the average position between the two middle folks.</li>
  <li><strong>Mode:</strong> The mode is the life of the party who shows up the most times. It's the value that appears most frequently in your data. Think of it as the dance move that everyone can't resist doing. Just like a favorite song played over and over, the mode is all about repetition.</li>
</ul>
<p>Let's put these measures to the test with a scenario. Imagine you're a pizza delivery person keeping track of the number of slices each customer orders. Your dataset might look like this: 2, 3, 4, 4, 4, 5, 6. The mean would be (2+3+4+4+4+5+6) / 7 = 28 / 7 = 4. The median, since there's an odd number of values, is the fourth value: 4 slices. And the mode is clearly 4 slices because it appears the most frequently.</p>
<p>So, just like understanding the pulse of a party helps you enjoy it more, comprehending measures of center helps you understand your data better and make informed decisions.</p>


      <p><strong>Measures of Variability:</strong></p>
      <p>Measures of variability tell us how spread out the data is. The range, variance, and standard deviation are common measures of variability.</p>
      <p>The <strong>range</strong> is the difference between the maximum and minimum values in the data.</p>
      <p>The <strong>variance</strong> measures how much the data values differ from the mean. It's calculated by finding the average of the squared differences from the mean.</p>
      <p>The <strong>standard deviation</strong> is the square root of the variance. It gives us an idea of how much individual data points deviate from the mean.</p>

      <p><strong>Population:</strong></p>
      <p>In statistics, a <strong>population</strong> refers to the entire group of individuals or objects that we're interested in studying. For example, if we're studying the heights of all students in a school, the population would be all the students in that school.</p>
      <p>However, it's often not practical to collect data from an entire population. Instead, we collect data from a <strong>sample</strong>, which is a smaller group that represents the population. By analyzing the sample, we can make inferences about the entire population.</p>
      
      <p><strong>Applying Statistics:</strong></p>
      <p>Statistics is used in various fields like science, business, and social studies. Scientists use statistics to analyze experimental data and draw conclusions. Businesses use it to make informed decisions based on market trends and customer behavior. Social scientists use statistics to study and understand human behavior and patterns.</p>

      <p><strong>Conclusion:</strong></p>
      <p>Understanding statistics helps us make sense of data and draw meaningful conclusions. Whether you're analyzing survey results, studying scientific data, or making informed decisions, statistics plays a crucial role in many aspects of our lives.</p>
      
      <p><strong>Example Problem 1: Calculating Mean</strong></p>
<p>Problem: Calculate the mean (average) of the following test scores: 85, 92, 78, 88, 95.</p>
<p>Solution:</p>
<p>Step 1: Add up all the test scores: 85 + 92 + 78 + 88 + 95 = 438.</p>
<p>Step 2: Divide the sum by the number of test scores (5): 438 / 5 = 87.6.</p>
<p>So, the mean test score is 87.6.</p>

<p><strong>Example Problem 2: Finding Median and Range</strong></p>
<p>Problem: Find the median and range of the following data set: 12, 18, 25, 30, 36.</p>
<p>Solution:</p>
<p>Step 1: Arrange the data in order: 12, 18, 25, 30, 36.</p>
<p>Step 2: Find the median. Since there are odd values, the median is the middle value, which is 25.</p>
<p>Step 3: Calculate the range by subtracting the minimum (12) from the maximum (36): 36 - 12 = 24.</p>
<p>So, the median is 25, and the range is 24.</p>
    </div>
  );
};

export default StatisticsTutorial;
