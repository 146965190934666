import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyARez1Ltw8Z10uZFRa72_6GAXvKaWa4yyo",
  authDomain: "for-math-68100.firebaseapp.com",
  projectId: "for-math-68100",
  storageBucket: "for-math-68100.appspot.com",
  messagingSenderId: "349429667404",
  appId: "1:349429667404:web:e479ff2bbd8bd35d89c6e9"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(app);