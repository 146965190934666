import React, { useState, useEffect, useMemo } from 'react';
import './Test.css';
import '../App.css';
import './Popup.css';
import Incorrectp from './Incorrectp';

	const questions = [
        {
          incorrectAnswerMessage: 'Sorry, that was incorrect. To find the mean of a set of numbers, you need to add all the numbers together and then divide the sum by the total count of numbers. For this set, (2 + 3 + 5 + 7 + 10) / 5 = 5.4.',
          questionText: 'What is the mean of the numbers 2, 3, 5, 7, and 10?',
          answerOptions: [
            { answerText: '6', isCorrect: false },
            { answerText: '5.4', isCorrect: true },
            { answerText: '7', isCorrect: false },
            { answerText: '4', isCorrect: false },
          ],
        },
        {
          incorrectAnswerMessage: 'Sorry, that was incorrect. To find the median of a set of numbers, you need to arrange the numbers in ascending order and then find the middle value. In this case, the median is 6.',
          questionText: 'What is the median of the numbers 4, 8, 3, 9, and 6?',
          answerOptions: [
            { answerText: '8', isCorrect: false },
            { answerText: '5', isCorrect: false },
            { answerText: '6', isCorrect: true },
            { answerText: '9', isCorrect: false },
          ],
        },
        {
          incorrectAnswerMessage: 'Sorry, that was incorrect. The probability of an event occurring is calculated by dividing the number of favorable outcomes by the total number of possible outcomes. In this case, the probability of rolling an even number is 3/6 or 1/2.',
          questionText: 'If you roll a fair six-sided die, what is the probability of rolling an even number?',
          answerOptions: [
            { answerText: '1/3', isCorrect: false },
            { answerText: '1/6', isCorrect: false },
            { answerText: '1/2', isCorrect: true },
            { answerText: '2/3', isCorrect: false },
          ],
        },
		{
			incorrectAnswerMessage: 'Sorry, that was incorrect. The mode is the number that appears most frequently in a set of data. In this set, 3 appears the most.',
			questionText: 'What is the mode of the numbers 2, 3, 3, 5, and 7?',
			answerOptions: [
			  { answerText: '3', isCorrect: true },
			  { answerText: '5', isCorrect: false },
			  { answerText: '2', isCorrect: false },
			  { answerText: '7', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The probability of an event not occurring is 1 minus the probability of the event occurring. In this case, the probability of not rolling a 4 is 1 - 1/6 = 5/6.',
			questionText: 'If you roll a fair six-sided die, what is the probability of not rolling a 4?',
			answerOptions: [
			  { answerText: '1/6', isCorrect: false },
			  { answerText: '5/6', isCorrect: true },
			  { answerText: '1/3', isCorrect: false },
			  { answerText: '2/3', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The range of a set of data is the difference between the largest and smallest values. In this case, the range is 9 - 1 = 8.',
			questionText: 'What is the range of the numbers 1, 3, 5, 7, and 9?',
			answerOptions: [
			  { answerText: '7', isCorrect: false },
			  { answerText: '6', isCorrect: false },
			  { answerText: '8', isCorrect: true },
			  { answerText: '9', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The probability of an event is calculated by dividing the number of successful outcomes by the total number of possible outcomes. In this case, the probability of drawing a red marble is 4/10.',
			questionText: 'In a bag, there are 4 red marbles and 6 blue marbles. What is the probability of drawing a red marble?',
			answerOptions: [
			  { answerText: '2/5', isCorrect: false },
			  { answerText: '4/10', isCorrect: true },
			  { answerText: '3/7', isCorrect: false },
			  { answerText: '1/2', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The mean absolute deviation (MAD) measures the average distance of data values from the mean. To calculate it, find the mean of the absolute differences between each data value and the mean. In this case, the MAD is (|3 - 5| + |5 - 5| + |7 - 5| + |9 - 5| + |4 - 5|) / 5 = 2.8.',
			questionText: 'Find the mean absolute deviation of the numbers 3, 5, 7, 9, and 4.',
			answerOptions: [
			  { answerText: '2.8', isCorrect: true },
			  { answerText: '3', isCorrect: false },
			  { answerText: '2', isCorrect: false },
			  { answerText: '1.6', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The interquartile range (IQR) is the difference between the first quartile (Q1) and the third quartile (Q3). In this case, the IQR is 8 - 4 = 4.',
			questionText: 'What is the interquartile range of the data set: 3, 7, 2, 8, 6, 4?',
			answerOptions: [
			  { answerText: '5', isCorrect: false },
			  { answerText: '6', isCorrect: false },
			  { answerText: '4', isCorrect: true },
			  { answerText: '8', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The theoretical probability of an event is calculated by dividing the number of successful outcomes by the total number of possible outcomes. In this case, the probability of rolling a 5 on a fair six-sided die is 1/6.',
			questionText: 'If you roll a fair six-sided die, what is the probability of rolling a 5?',
			answerOptions: [
			  { answerText: '1/4', isCorrect: false },
			  { answerText: '1/2', isCorrect: false },
			  { answerText: '1/6', isCorrect: true },
			  { answerText: '2/3', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The mode of a data set is the number that appears most frequently. In this case, the mode is 3.',
			questionText: 'What is the mode of the data set: 2, 3, 5, 3, 7, 6, 3, 2, 4?',
			answerOptions: [
			  { answerText: '4', isCorrect: false },
			  { answerText: '5', isCorrect: false },
			  { answerText: '3', isCorrect: true },
			  { answerText: '7', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The probability of an event is calculated by dividing the number of successful outcomes by the total number of possible outcomes. In this case, the probability of drawing a blue marble is 6/12 or 1/2.',
			questionText: 'In a bag, there are 6 blue marbles and 6 red marbles. What is the probability of drawing a blue marble?',
			answerOptions: [
			  { answerText: '1/4', isCorrect: false },
			  { answerText: '1/2', isCorrect: true },
			  { answerText: '3/4', isCorrect: false },
			  { answerText: '2/3', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. To find the probability of an event occurring, divide the number of favorable outcomes by the total number of possible outcomes. In this case, the probability of drawing a red card is 26/52 or 1/2.',
			questionText: 'A standard deck of cards has 52 cards, including 26 red cards and 26 black cards. What is the probability of drawing a red card?',
			answerOptions: [
			  { answerText: '1/4', isCorrect: false },
			  { answerText: '1/2', isCorrect: true },
			  { answerText: '3/4', isCorrect: false },
			  { answerText: '1/3', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The complementary event of rolling an odd number on a fair six-sided die is rolling an even number. The probability of rolling an even number is 3/6 or 1/2.',
			questionText: 'If you roll a fair six-sided die, what is the probability of rolling an odd number?',
			answerOptions: [
			  { answerText: '1/4', isCorrect: false },
			  { answerText: '1/2', isCorrect: true },
			  { answerText: '3/4', isCorrect: false },
			  { answerText: '2/3', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The median is the middle value of a data set when arranged in ascending order. In this case, the median is 7.',
			questionText: 'What is the median of the numbers 5, 8, 2, 9, 7?',
			answerOptions: [
			  { answerText: '7', isCorrect: true },
			  { answerText: '6', isCorrect: false },
			  { answerText: '5', isCorrect: false },
			  { answerText: '8', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The probability of an event is calculated by dividing the number of successful outcomes by the total number of possible outcomes. In this case, the probability of drawing a red marble is 4/10 or 2/5.',
			questionText: 'In a bag, there are 4 red marbles and 6 green marbles. What is the probability of drawing a red marble?',
			answerOptions: [
			  { answerText: '1/3', isCorrect: false },
			  { answerText: '2/5', isCorrect: true },
			  { answerText: '3/5', isCorrect: false },
			  { answerText: '2/3', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The mode of a data set is the number that appears most frequently. In this case, the mode is 2.',
			questionText: 'What is the mode of the data set: 3, 2, 5, 2, 7, 6, 2, 4?',
			answerOptions: [
			  { answerText: '4', isCorrect: false },
			  { answerText: '5', isCorrect: false },
			  { answerText: '2', isCorrect: true },
			  { answerText: '7', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The range of a set of numbers is the difference between the largest and smallest values. In this case, the range is 10 - 4 = 6.',
			questionText: 'What is the range of the numbers 4, 8, 6, 5, 7, 10?',
			answerOptions: [
			  { answerText: '6', isCorrect: true },
			  { answerText: '5', isCorrect: false },
			  { answerText: '7', isCorrect: false },
			  { answerText: '4', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The mean of a set of numbers is calculated by adding up all the numbers and then dividing by the total count. In this case, the mean is (15 + 20 + 12 + 18 + 25) / 5 = 18.',
			questionText: 'What is the mean of the numbers 15, 20, 12, 18, and 25?',
			answerOptions: [
			  { answerText: '20', isCorrect: false },
			  { answerText: '18', isCorrect: true },
			  { answerText: '15', isCorrect: false },
			  { answerText: '25', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The mode of a data set is the number that appears most frequently. In this case, the mode is 3.',
			questionText: 'What is the mode of the data set: 4, 5, 3, 2, 3, 6, 7, 3?',
			answerOptions: [
			  { answerText: '4', isCorrect: false },
			  { answerText: '5', isCorrect: false },
			  { answerText: '3', isCorrect: true },
			  { answerText: '6', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The probability of selecting a blue marble is calculated by dividing the number of blue marbles by the total number of marbles. In this case, the probability is 3/8.',
			questionText: 'In a bag, there are 3 blue marbles, 2 red marbles, and 3 green marbles. What is the probability of selecting a blue marble?',
			answerOptions: [
			  { answerText: '1/3', isCorrect: false },
			  { answerText: '3/8', isCorrect: true },
			  { answerText: '2/5', isCorrect: false },
			  { answerText: '1/4', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The mode of a data set is the value that appears most frequently. In this case, the mode is 8.',
			questionText: 'What is the mode of the data set: 5, 7, 8, 6, 8, 9, 8, 3?',
			answerOptions: [
			  { answerText: '5', isCorrect: false },
			  { answerText: '7', isCorrect: false },
			  { answerText: '8', isCorrect: true },
			  { answerText: '6', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. To calculate the probability of an event, divide the number of favorable outcomes by the total number of possible outcomes. In this case, the probability of rolling an odd number is 3/6 or 1/2.',
			questionText: 'If you roll a fair six-sided die, what is the probability of rolling an odd number?',
			answerOptions: [
			  { answerText: '1/3', isCorrect: false },
			  { answerText: '1/2', isCorrect: true },
			  { answerText: '2/3', isCorrect: false },
			  { answerText: '1/6', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The median of a data set is the middle value when the data is ordered from least to greatest. In this case, the median is 6.',
			questionText: 'What is the median of the data set: 4, 7, 3, 9, 6, 2, 8?',
			answerOptions: [
			  { answerText: '6', isCorrect: true },
			  { answerText: '4', isCorrect: false },
			  { answerText: '5', isCorrect: false },
			  { answerText: '7', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The mean of a data set is calculated by summing up all the values and then dividing by the total number of values. In this case, the mean is 7.',
			questionText: 'What is the mean of the data set: 5, 7, 8, 6, 9?',
			answerOptions: [
			  { answerText: '6', isCorrect: false },
			  { answerText: '7', isCorrect: true },
			  { answerText: '8', isCorrect: false },
			  { answerText: '9', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. To calculate the probability of independent events occurring together, you multiply the individual probabilities. In this case, the probability is 1/2 * 1/2 = 1/4.',
			questionText: 'If you flip a fair coin and roll a fair six-sided die, what is the probability of getting heads on the coin and an even number on the die?',
			answerOptions: [
			  { answerText: '1/4', isCorrect: true },
			  { answerText: '1/6', isCorrect: false },
			  { answerText: '1/2', isCorrect: false },
			  { answerText: '1/3', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The range of a set of numbers is the difference between the largest and smallest values. In this case, the range is 12 - 3 = 9.',
			questionText: 'What is the range of the data set: 5, 8, 6, 12, 3, 9?',
			answerOptions: [
			  { answerText: '9', isCorrect: true },
			  { answerText: '7', isCorrect: false },
			  { answerText: '8', isCorrect: false },
			  { answerText: '6', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The range of a set of data is the difference between the largest and smallest values. In this case, the range is 12 - 5 = 7.',
			questionText: 'What is the range of the data set: 5, 8, 9, 10, 12?',
			answerOptions: [
			  { answerText: '8', isCorrect: false },
			  { answerText: '9', isCorrect: false },
			  { answerText: '7', isCorrect: true },
			  { answerText: '10', isCorrect: false },
			],
		  },
		  {
			incorrectAnswerMessage: 'Sorry, that was incorrect. The range of a set of numbers is the difference between the largest and smallest values. In this case, the range is 14 - 7 = 7.',
			questionText: 'What is the range of the numbers 7, 12, 14, 8, and 10?',
			answerOptions: [
			  { answerText: '6', isCorrect: false },
			  { answerText: '7', isCorrect: true },
			  { answerText: '8', isCorrect: false },
			  { answerText: '10', isCorrect: false },
			],
		  },
        {
          incorrectAnswerMessage: 'Sorry, that was incorrect. The range of a set of numbers is the difference between the largest and smallest values. In this case, the range is 9 - 2 = 7.',
          questionText: 'What is the range of the numbers 2, 5, 7, 9, and 4?',
          answerOptions: [
            { answerText: '4', isCorrect: false },
            { answerText: '6', isCorrect: false },
            { answerText: '7', isCorrect: true },
            { answerText: '9', isCorrect: false },
          ],
        },
      ];   			

	  export default function Dataprob() {

	  const [currentQuestion, setCurrentQuestion] = useState(0);
	  const [showScore, setShowScore] = useState(false);
	  const [score, setScore] = useState(0);
	  const [showPopup, setShowPopup] = useState(false);
	  const [popupMessage, setPopupMessage] = useState('');
  
	  const shuffledQuestions = useMemo(() => {
		  // Function to shuffle the questions array
		  const shuffleQuestions = () => {
			const shuffledQuestions = [...questions];
			for (let i = shuffledQuestions.length - 1; i > 0; i--) {
			  const j = Math.floor(Math.random() * (i + 1));
			  [shuffledQuestions[i], shuffledQuestions[j]] = [
				shuffledQuestions[j],
				shuffledQuestions[i],
			  ];
			}
			return shuffledQuestions;
		  };
	  
		  const result = shuffleQuestions();
		  console.log(result); // Add this line to log the shuffledQuestions
		  return result;
  
		}, []);
  
		useEffect(() => {
		  // The effect logic here (if needed)
		}, [shuffledQuestions]);
  
	  const handleAnswerOptionClick = (isCorrect, incorrectAnswerMessage) => {
		  if (isCorrect) {
			setScore(score + 1);
		  } else {
			setShowPopup(true);
			setPopupMessage(incorrectAnswerMessage);
		  }
		
		  const nextQuestion = currentQuestion + 1;
		  if (nextQuestion < questions.length) {
			setCurrentQuestion(nextQuestion);
		  } else {
			setShowScore(true);
		  }
		};
		
	  
		const handleClosePopup = () => {
		  // Close the pop-up and proceed to the next question
		  setShowPopup(false);
		  setCurrentQuestion(currentQuestion);
		  setPopupMessage('');
		};
	  
		return (
		  <div className='entireT'>
			<div style={{ textAlign: 'center', marginBottom: '20px' }}>
			  <h2>Data And Probability Math Practice</h2>
			  Take notes for incorrect answers.
			</div>
			<div className='Test'>
			{showScore ? (
			  <div className='score-section'>
				You scored {score} out of {shuffledQuestions.length}
			  </div>
			) : currentQuestion < shuffledQuestions.length ? (
			  <>
				<div className='question-section'>
				  <div className='question-count'>
					<span>Question {currentQuestion + 1}</span>/{shuffledQuestions.length}
				  </div>
				  <div className='question-text'>
					{shuffledQuestions[currentQuestion].questionText}
				  </div>
				</div>
				
				<div className='answer-section'>
				  {shuffledQuestions[currentQuestion].answerOptions.map((answerOption) => (
					<button
					  key={answerOption.answerText}
					  onClick={() =>
						handleAnswerOptionClick(
						  answerOption.isCorrect,
						  shuffledQuestions[currentQuestion].incorrectAnswerMessage
						)
					  }
					>
					  {answerOption.answerText}
					</button>
				  ))}
				</div>
			  </>
			) : null}
			
			{showPopup && (
			  <div className='popup-card'>
				<Incorrectp message={popupMessage} onClose={handleClosePopup} />
			  </div>
			)}
		  </div>
		</div>
	  );
			}