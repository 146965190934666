import React, { useState, useEffect, useMemo } from 'react';
import './Test.css';
import './Popup.css';
import '../App.css';
import Incorrectp from './Incorrectp';

  const questions = [
    {
      incorrectAnswerMessage: 'The sum of angles in a triangle is always 180 degrees. In this case, 60 + 90 + 70 = 220 degrees, which is greater than 180 degrees.',
      questionText: 'What is the sum of the interior angles in a triangle?',
      answerOptions: [
        { answerText: '90 degrees', isCorrect: false },
        { answerText: '180 degrees', isCorrect: true },
        { answerText: '360 degrees', isCorrect: false },
        { answerText: '120 degrees', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The area of a triangle can be calculated using the formula: Area = (base × height) / 2. In this case, Area = (6 × 4) / 2 = 12 square units.',
      questionText: 'What is the area of a triangle with base 6 units and height 4 units?',
      answerOptions: [
        { answerText: '8 square units', isCorrect: false },
        { answerText: '12 square units', isCorrect: true },
        { answerText: '18 square units', isCorrect: false },
        { answerText: '24 square units', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The circumference of a circle can be calculated using the formula: Circumference = 2 × π × radius. In this case, Circumference = 2 × 3.14 × 5 ≈ 31.4 units.',
      questionText: 'What is the circumference of a circle with a radius of 5 units? (Use π ≈ 3.14)',
      answerOptions: [
        { answerText: '15.7 units', isCorrect: false },
        { answerText: '31.4 units', isCorrect: true },
        { answerText: '10.5 units', isCorrect: false },
        { answerText: '25.12 units', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The perimeter of a rectangle can be calculated using the formula: Perimeter = 2 × (length + width). In this case, Perimeter = 2 × (10 + 6) = 32 units.',
      questionText: 'What is the perimeter of a rectangle with length 10 units and width 6 units?',
      answerOptions: [
        { answerText: '16 units', isCorrect: false },
        { answerText: '22 units', isCorrect: false },
        { answerText: '28 units', isCorrect: false },
        { answerText: '32 units', isCorrect: true },
      ],
    },
    {
      incorrectAnswerMessage: 'The area of a parallelogram can be calculated using the formula: Area = base × height. In this case, Area = 7 × 9 = 63 square units.',
      questionText: 'What is the area of a parallelogram with base 7 units and height 9 units?',
      answerOptions: [
        { answerText: '16 square units', isCorrect: false },
        { answerText: '45 square units', isCorrect: false },
        { answerText: '54 square units', isCorrect: false },
        { answerText: '63 square units', isCorrect: true },
      ],
    },
    {
      incorrectAnswerMessage: 'The area of a triangle can be calculated using the formula: Area = (base × height) / 2. In this case, Area = (10 × 8) / 2 = 40 square units.',
      questionText: 'What is the area of a triangle with base 10 units and height 8 units?',
      answerOptions: [
        { answerText: '16 square units', isCorrect: false },
        { answerText: '30 square units', isCorrect: false },
        { answerText: '36 square units', isCorrect: false },
        { answerText: '40 square units', isCorrect: true },
      ],
    },
    {
      incorrectAnswerMessage: 'The circumference of a circle can be calculated using the formula: Circumference = 2 × π × radius. In this case, Circumference = 2 × 3.14 × 9.5 ≈ 59.64 units.',
      questionText: 'What is the circumference of a circle with a radius of 9.5 units? (Use π ≈ 3.14)',
      answerOptions: [
        { answerText: '19 units', isCorrect: false },
        { answerText: '47.3 units', isCorrect: false },
        { answerText: '54 units', isCorrect: false },
        { answerText: '59.64 units', isCorrect: true },
      ],
    },
    {
      incorrectAnswerMessage: 'The volume of a cylinder can be calculated using the formula: Volume = π × radius^2 × height. In this case, Volume = 3.14 × 4.5^2 × 6 ≈ 382.5 cubic units.',
      questionText: 'What is the volume of a cylinder with a radius of 4.5 units and height of 6 units? (Use π ≈ 3.14)',
      answerOptions: [
        { answerText: '81 cubic units', isCorrect: false },
        { answerText: '243 cubic units', isCorrect: false },
        { answerText: '337.5 cubic units', isCorrect: false },
        { answerText: '382.5 cubic units', isCorrect: true },
      ],
    },
    {
      incorrectAnswerMessage: 'The sum of the interior angles of a quadrilateral is always 360 degrees.',
      questionText: 'What is the sum of the interior angles of a quadrilateral?',
      answerOptions: [
        { answerText: '180 degrees', isCorrect: false },
        { answerText: '270 degrees', isCorrect: false },
        { answerText: '360 degrees', isCorrect: true },
        { answerText: '450 degrees', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The perimeter of a square can be calculated by adding up all its four equal sides. In this case, Perimeter = 4 × 12 = 48 units.',
      questionText: 'What is the perimeter of a square with side length 12 units?',
      answerOptions: [
        { answerText: '24 units', isCorrect: false },
        { answerText: '36 units', isCorrect: false },
        { answerText: '48 units', isCorrect: true },
        { answerText: '60 units', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The formula for the volume of a rectangular prism is: Volume = length × width × height. In this case, Volume = 6 × 7 × 9 = 378 cubic units.',
      questionText: 'What is the volume of a rectangular prism with length 6 units, width 7 units, and height 9 units?',
      answerOptions: [
        { answerText: '252 cubic units', isCorrect: false },
        { answerText: '324 cubic units', isCorrect: false },
        { answerText: '378 cubic units', isCorrect: true },
        { answerText: '432 cubic units', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The formula for the area of a trapezoid is: Area = 0.5 × (sum of bases) × height. In this case, Area = 0.5 × (5 + 10) × 8 = 60 square units.',
      questionText: 'What is the area of a trapezoid with bases of lengths 5 units and 10 units, and height 8 units?',
      answerOptions: [
        { answerText: '35 square units', isCorrect: false },
        { answerText: '45 square units', isCorrect: false },
        { answerText: '55 square units', isCorrect: false },
        { answerText: '60 square units', isCorrect: true },
      ],
    },
    {
      incorrectAnswerMessage: 'The formula for the volume of a cone is: Volume = (1/3) × π × radius^2 × height. In this case, Volume = (1/3) × 3.14 × 5^2 × 12 ≈ 314 cubic units.',
      questionText: 'What is the volume of a cone with a radius of 5 units and height of 12 units? (Use π ≈ 3.14)',
      answerOptions: [
        { answerText: '125 cubic units', isCorrect: false },
        { answerText: '251.33 cubic units', isCorrect: false },
        { answerText: '314 cubic units', isCorrect: true },
        { answerText: '628 cubic units', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The formula for the area of a parallelogram is: Area = base × height. In this case, Area = 10 × 6 = 60 square units.',
      questionText: 'What is the area of a parallelogram with base 10 units and height 6 units?',
      answerOptions: [
        { answerText: '20 square units', isCorrect: false },
        { answerText: '40 square units', isCorrect: false },
        { answerText: '60 square units', isCorrect: true },
        { answerText: '80 square units', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The formula for the surface area of a rectangular prism is: Surface Area = 2lw + 2lh + 2wh. In this case, Surface Area = 2 × 5 × 7 + 2 × 5 × 9 + 2 × 7 × 9 = 214 square units.',
      questionText: 'What is the surface area of a rectangular prism with length 5 units, width 7 units, and height 9 units?',
      answerOptions: [
        { answerText: '162 square units', isCorrect: false },
        { answerText: '188 square units', isCorrect: false },
        { answerText: '214 square units', isCorrect: true },
        { answerText: '238 square units', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The formula for the circumference of a circle is: Circumference = 2 × π × radius. In this case, Circumference = 2 × 3.14 × 10 ≈ 62.8 units.',
      questionText: 'What is the circumference of a circle with a radius of 10 units? (Use π ≈ 3.14)',
      answerOptions: [
        { answerText: '20 units', isCorrect: false },
        { answerText: '31.4 units', isCorrect: false },
        { answerText: '62.8 units', isCorrect: true },
        { answerText: '100 units', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'A cube has all sides of equal length, and all its angles are right angles.',
      questionText: 'What is the measure of each angle in a cube?',
      answerOptions: [
        { answerText: '60 degrees', isCorrect: false },
        { answerText: '90 degrees', isCorrect: true },
        { answerText: '120 degrees', isCorrect: false },
        { answerText: '180 degrees', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The formula for the area of a trapezoid is: Area = (1/2) × (base1 + base2) × height. In this case, Area = (1/2) × (6 + 12) × 8 = 72 square units.',
      questionText: 'What is the area of a trapezoid with base1 of length 6 units, base2 of length 12 units, and height 8 units?',
      answerOptions: [
        { answerText: '24 square units', isCorrect: false },
        { answerText: '48 square units', isCorrect: false },
        { answerText: '72 square units', isCorrect: true },
        { answerText: '96 square units', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The formula for the volume of a cone is: Volume = (1/3) × π × radius^2 × height. In this case, Volume = (1/3) × 3.14 × 5^2 × 9 ≈ 235.5 cubic units.',
      questionText: 'What is the volume of a cone with a radius of 5 units and height of 9 units? (Use π ≈ 3.14)',
      answerOptions: [
        { answerText: '47.1 cubic units', isCorrect: false },
        { answerText: '157.0 cubic units', isCorrect: false },
        { answerText: '235.5 cubic units', isCorrect: true },
        { answerText: '314.0 cubic units', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'A rectangle is a quadrilateral with opposite sides that are equal in length and all angles are right angles.',
      questionText: 'What are the properties of a rectangle?',
      answerOptions: [
        { answerText: 'Opposite sides are parallel and all angles are right angles.', isCorrect: true },
        { answerText: 'All sides are equal in length and all angles are right angles.', isCorrect: false },
        { answerText: 'Opposite sides are parallel and all angles are acute angles.', isCorrect: false },
        { answerText: 'All sides are equal in length and all angles are obtuse angles.', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The formula for the area of a circle is: Area = π × radius^2. In this case, Area = 3.14 × 9^2 ≈ 254.34 square units.',
      questionText: 'What is the area of a circle with a radius of 9 units? (Use π ≈ 3.14)',
      answerOptions: [
        { answerText: '63.36 square units', isCorrect: false },
        { answerText: '226.08 square units', isCorrect: false },
        { answerText: '254.34 square units', isCorrect: true },
        { answerText: '408.24 square units', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The formula for the perimeter of a rectangle is: Perimeter = 2 × (length + width). In this case, Perimeter = 2 × (10 + 7) = 34 units.',
      questionText: 'What is the perimeter of a rectangle with length 10 units and width 7 units?',
      answerOptions: [
        { answerText: '24 units', isCorrect: false },
        { answerText: '30 units', isCorrect: false },
        { answerText: '34 units', isCorrect: true },
        { answerText: '44 units', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The formula for the volume of a cylinder is: Volume = π × radius^2 × height. In this case, Volume = 3.14 × 4^2 × 10 ≈ 502.4 cubic units.',
      questionText: 'What is the volume of a cylinder with a radius of 4 units and height of 10 units? (Use π ≈ 3.14)',
      answerOptions: [
        { answerText: '100.48 cubic units', isCorrect: false },
        { answerText: '251.2 cubic units', isCorrect: false },
        { answerText: '502.4 cubic units', isCorrect: true },
        { answerText: '753.6 cubic units', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'A rhombus has all sides of equal length, but its angles are not necessarily 90 degrees.',
      questionText: 'What is the measure of each angle in a rhombus?',
      answerOptions: [
        { answerText: '60 degrees', isCorrect: false },
        { answerText: '90 degrees', isCorrect: false },
        { answerText: '120 degrees', isCorrect: false },
        { answerText: '180 degrees', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The formula for the surface area of a sphere is: Surface Area = 4 × π × radius^2. In this case, Surface Area = 4 × 3.14 × 8^2 ≈ 803.84 square units.',
      questionText: 'What is the surface area of a sphere with a radius of 8 units? (Use π ≈ 3.14)',
      answerOptions: [
        { answerText: '128 square units', isCorrect: false },
        { answerText: '200.96 square units', isCorrect: false },
        { answerText: '502.88 square units', isCorrect: false },
        { answerText: '803.84 square units', isCorrect: true },
      ],
    },
    {
      incorrectAnswerMessage: 'The volume of a rectangular prism can be calculated using the formula: Volume = length × width × height. In this case, Volume = 4 × 3 × 5 = 60 cubic units.',
      questionText: 'What is the volume of a rectangular prism with length 4 units, width 3 units, and height 5 units?',
      answerOptions: [
        { answerText: '20 cubic units', isCorrect: false },
        { answerText: '30 cubic units', isCorrect: false },
        { answerText: '45 cubic units', isCorrect: false },
        { answerText: '60 cubic units', isCorrect: true },
      ],
    },
    {
      incorrectAnswerMessage: 'Sorry, that was incorrect. The sum of the angles in a triangle is always 180 degrees.',
      questionText: 'What is the sum of the interior angles of a triangle?',
      answerOptions: [
        { answerText: '90 degrees', isCorrect: false },
        { answerText: '120 degrees', isCorrect: false },
        { answerText: '180 degrees', isCorrect: true },
        { answerText: '270 degrees', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The area of a rectangle can be calculated using the formula: Area = length × width. In this case, Area = 8 × 5 = 40 square units.',
      questionText: 'What is the area of a rectangle with length 8 units and width 5 units?',
      answerOptions: [
        { answerText: '13 square units', isCorrect: false },
        { answerText: '30 square units', isCorrect: false },
        { answerText: '40 square units', isCorrect: true },
        { answerText: '56 square units', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'The circumference of a circle can be calculated using the formula: Circumference = 2 × π × radius. In this case, Circumference = 2 × 3.14 × 7 ≈ 43.96 units.',
      questionText: 'What is the circumference of a circle with a radius of 7 units? (Use π ≈ 3.14)',
      answerOptions: [
        { answerText: '22 units', isCorrect: false },
        { answerText: '43.96 units', isCorrect: true },
        { answerText: '14 units', isCorrect: false },
        { answerText: '32 units', isCorrect: false },
      ],
    },
    {
      incorrectAnswerMessage: 'A right angle measures 90 degrees. In this case, the angle measures 120 degrees, which is greater than 90 degrees.',
      questionText: 'What is the measure of a right angle?',
      answerOptions: [
        { answerText: '45 degrees', isCorrect: false },
        { answerText: '90 degrees', isCorrect: true },
        { answerText: '120 degrees', isCorrect: false },
        { answerText: '180 degrees', isCorrect: false },
      ],
    },
  ];

  export default function Geometry() {

  const [currentQuestion, setCurrentQuestion] = useState(0);
	const [showScore, setShowScore] = useState(false);
	const [score, setScore] = useState(0);
	const [showPopup, setShowPopup] = useState(false);
	const [popupMessage, setPopupMessage] = useState('');

	const shuffledQuestions = useMemo(() => {
		// Function to shuffle the questions array
		const shuffleQuestions = () => {
		  const shuffledQuestions = [...questions];
		  for (let i = shuffledQuestions.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[shuffledQuestions[i], shuffledQuestions[j]] = [
			  shuffledQuestions[j],
			  shuffledQuestions[i],
			];
		  }
		  return shuffledQuestions;
		};
	
		const result = shuffleQuestions();
		console.log(result); // Add this line to log the shuffledQuestions
		return result;

	  }, []);

	  useEffect(() => {
		// The effect logic here (if needed)
	  }, [shuffledQuestions]);

	const handleAnswerOptionClick = (isCorrect, incorrectAnswerMessage) => {
		if (isCorrect) {
		  setScore(score + 1);
		} else {
		  setShowPopup(true);
		  setPopupMessage(incorrectAnswerMessage);
		}
	  
		const nextQuestion = currentQuestion + 1;
		if (nextQuestion < questions.length) {
		  setCurrentQuestion(nextQuestion);
		} else {
		  setShowScore(true);
		}
	  };
	  
	
	  const handleClosePopup = () => {
		// Close the pop-up and proceed to the next question
		setShowPopup(false);
		setCurrentQuestion(currentQuestion);
		setPopupMessage('');
	  };
	
	  return (
		<div className='entireT'>
		  <div style={{ textAlign: 'center', marginBottom: '20px' }}>
			<h2>Geometry Math Practice</h2>
      Take notes for incorrect answers.
		  </div>
		  <div className='Test'>
			{showScore ? (
			  <div className='score-section'>
				You scored {score} out of {shuffledQuestions.length}
			  </div>
			) : currentQuestion < shuffledQuestions.length ? (
			  <>
				<div className='question-section'>
				  <div className='question-count'>
					<span>Question {currentQuestion + 1}</span>/{shuffledQuestions.length}
				  </div>
				  <div className='question-text'>
					{shuffledQuestions[currentQuestion].questionText}
				  </div>
				</div>
				
				<div className='answer-section'>
				  {shuffledQuestions[currentQuestion].answerOptions.map((answerOption) => (
					<button
					  key={answerOption.answerText}
					  onClick={() =>
						handleAnswerOptionClick(
						  answerOption.isCorrect,
						  shuffledQuestions[currentQuestion].incorrectAnswerMessage
						)
					  }
					>
					  {answerOption.answerText}
					</button>
				  ))}
				</div>
			  </>
			) : null}
			
			{showPopup && (
			  <div className='popup-card'>
				<Incorrectp message={popupMessage} onClose={handleClosePopup} />
			  </div>
			)}
		  </div>
		</div>
	  );
			}