import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import badgeImage from '../img/badgethree.png';
import Modal from 'react-modal';
import { db, auth } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import './CExpression.css'; // Import your CSS file for styling


function CExponents() {

  const [cetaCompleted, setCetaCompleted] = useState(false);
  const [cetbCompleted, setCetbCompleted] = useState(false);
  const [cetcCompleted, setCetcCompleted] = useState(false);
  const [showCompleteButton, setShowCompleteButton] = useState(false);
  const [badgeClaimed, setBadgeClaimed] = useState(false);

  const user = auth.currentUser;

  useEffect(() => {
    const checkCEAandCEBFields = async () => {
      try {
        if (user) {
          const userRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(userRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            const cetaValue = userData.ceta;
            const cetbValue = userData.cetb;
            const cetcValue = userData.cetc;
            const badgethreeValue = userData.badgethree;

            if (cetaValue === 1) {
              setCetaCompleted(true);
            }
            if (cetbValue === 1) {
              setCetbCompleted(true);
            }
            if (cetcValue === 1) {
              setCetcCompleted(true);
            }

            if (cetaValue === 1 && cetbValue === 1 && cetcValue === 1) {
              setShowCompleteButton(true);
            }

            if (badgethreeValue === 1) {
              setBadgeClaimed(true);
            }
          }
        }
      } catch (error) {
        console.error('Error checking CETA and CETB and CETC fields:', error);
      }
    };

    checkCEAandCEBFields();
  }, [user]);

  const [badgeModalIsOpen, setBadgeModalIsOpen] = useState(false);

  const handleComplete = async () => {
    try {
      const userRef = doc(db, 'users', user.uid);

      // Update the Firestore database with the "badgeone" field set to 1
      await setDoc(userRef, { badgethree: 1 }, { merge: true });

      // Log the successful update (you can remove this if not needed)
      console.log('Badgeone field updated to 1');

      // Update state to indicate badge claimed and open the badge modal
      setBadgeClaimed(true);
      setBadgeModalIsOpen(true);
    } catch (error) {
      console.error('Error updating badgeone field:', error);
    }
  };

  return (
    <div className='wpage'>
      <br></br>
    <div className='epage'>
    <center><h1>3 Exponent Questions</h1></center><br></br>
    <b>Are you ready to delve into the world of exponential numbers? Embark on the exciting journey of the "3 Exponents Questions" challenge, where you'll conquer three thought-provoking exponent-based puzzles. By mastering these questions, you'll earn the prestigious Exponential Excellence badge – a symbol of your mastery over exponents and a key to unlocking a realm of mathematical brilliance.

    <h2>Achieve Exponential Excellence:</h2>
Step up to the challenge and embrace the power of exponential numbers. With each question you conquer, you'll inch closer to claiming the Exponential Excellence badge. Sharpen your problem-solving skills, immerse yourself in the realm of exponents, and proudly showcase your achievement to the world. The Exponential Excellence badge is your mark of distinction in the world of mathematics – are you prepared to rise to the occasion?</b><br></br><br></br>
<br></br><div className='aniexp'>
    <div className="card">
        <Link to="/CETa" className="button-link">
          <button className={`button ${!user ? 'disabled' : ''}`} disabled={!user}>
            {user ? 'Problem 1' : 'Sign in to do that'}
            <span className={`checkbox ${cetaCompleted ? 'checked' : ''}`}></span>
          </button>
        </Link>
        <Link to="/CETb" className="button-link">
          <button className={`button ${!user ? 'disabled' : ''}`} disabled={!user}>
            {user ? 'Problem 2' : 'Sign in to do that'}
            <span className={`checkbox ${cetbCompleted ? 'checked' : ''}`}></span>
          </button>
      </Link>
      <Link to="/CETc" className="button-link">
          <button className={`button ${!user ? 'disabled' : ''}`} disabled={!user}>
            {user ? 'Problem 2' : 'Sign in to do that'}
            <span className={`checkbox ${cetcCompleted ? 'checked' : ''}`}></span>
          </button>
      </Link>
      {showCompleteButton && !badgeClaimed ? (
        <button className="complete-button" onClick={handleComplete}>
          Complete
        </button>
      ) : (
        badgeClaimed && <p>Claimed</p>
      )}
      <Modal
        isOpen={badgeModalIsOpen}
        onRequestClose={() => setBadgeModalIsOpen(false)}
        contentLabel="Badge Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <h2>You received 1 Exponential Excellence Badge</h2><center>
        <img src={badgeImage} alt="Excellence Badge" style={{ width: '50px', height: '50px' }} /></center>
        <button onClick={() => setBadgeModalIsOpen(false)}>Close</button>
      </Modal>
      </div><br></br><br></br></div></div><Link to="/" className="back-button">
      <center><button className='bckbttn'>Back</button></center>
  </Link></div>
  );
}

export default CExponents;
