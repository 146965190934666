import React from 'react';
import './FunctionsTutorial.css';

const FunctionsTutorial = () => {
  return (
    <div className="functions-tutorial">
      <h1>Functions Tutorial for Middle School Math</h1>
      {/* Add content for Functions tutorial here */}
      <p><strong>Introduction to Functions:</strong></p>
      <p>Functions are a fundamental concept in middle school math that describe relationships between inputs and outputs. A function assigns exactly one output to each input. It is like a rule or a machine that takes an input and produces a corresponding output.</p>
      <p>For example, the rule "double the input" is a function. If we input 3, the output will be 6. If we input 5, the output will be 10, and so on.</p>

      <p><strong>Linear and Nonlinear Functions:</strong></p>
      <p>Functions can be classified as linear or nonlinear. Linear functions create straight-line graphs, and their rate of change is constant. Nonlinear functions, on the other hand, have curved graphs, and their rate of change is not constant.</p>
      <p>Linear Function Example: y = 2x + 3</p>
      <p>Nonlinear Function Example: y = x^2</p>
      
      <p><strong>Graphing Functions:</strong></p>
      <p>Graphs are visual representations of functions. In a graph, the input (x-axis) is plotted against the output (y-axis). For linear functions, the graph will be a straight line, while nonlinear functions will have curves.</p>
      <p>For example, the graph of y = 2x + 3 will be a straight line, while the graph of y = x^2 will be a parabola.</p>

      <p><strong>Real-Life Examples:</strong></p>
      <p>Functions are used in various real-life situations:</p>
      <ul>
        <li>Distance traveled by a car over time.</li>
        <li>Cost of buying a certain number of items at a store.</li>
        <li>Height of a growing plant over time.</li>
      </ul>
      <p>These examples demonstrate how inputs (time, number of items) are related to outputs (distance, cost, height) through functions.</p>

      <p><strong>Example 1: Temperature Conversion</strong></p>
      <p>One real-life example of a function is temperature conversion. The function that converts temperatures from Celsius to Fahrenheit is:</p>
      <p>Fahrenheit (F) = (Celsius (C) * 9/5) + 32</p>
      <p>For instance, if we want to convert 20 degrees Celsius to Fahrenheit:</p>
      <p>Fahrenheit (F) = (20 * 9/5) + 32 = 68</p>
      <p>So, 20 degrees Celsius is equal to 68 degrees Fahrenheit.</p>
      <p><em>Explanation:</em></p>
      <p>In this example, we have a linear function for temperature conversion. The formula to convert Celsius to Fahrenheit involves a constant rate of change (9/5) and a fixed starting point (32). The graph of this function will be a straight line, and the rate of change will remain the same for any input (temperature in Celsius). Therefore, the temperature conversion function is linear.</p>

      <p><strong>Example 2: Savings Account Balance</strong></p>
      <p>Another example of a function is the balance of a savings account over time. Let's say you deposit $100 into a savings account that earns 5% interest per year. The function for the account balance over time (in years) is:</p>
      <p>Balance (B) = 100 * (1 + 0.05)^t</p>
      <p>Where 't' represents the number of years. So, after 1 year:</p>
      <p>Balance (B) = 100 * (1 + 0.05)^1 = 105</p>
      <p>After 2 years:</p>
      <p>Balance (B) = 100 * (1 + 0.05)^2 = 110.25</p>
      <p>And so on. The balance in the savings account increases over time due to the interest earned.</p>
      <p><em>Explanation:</em></p>
      <p>In this example, we have a nonlinear function for the savings account balance over time. The interest rate (5%) causes the balance to grow exponentially. As the number of years increases, the balance will increase at an increasing rate due to the effect of compounding interest. The graph of this function will be a curve, and the rate of change is not constant. Therefore, the savings account balance function is nonlinear.</p>
    </div>
  );
};

export default FunctionsTutorial;
