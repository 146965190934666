import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './CExpression.css';
import badgeImage from '../img/badgeone.png';
import Modal from 'react-modal';
import { db, auth } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

function CExpressions() {
  const [ceaCompleted, setCeaCompleted] = useState(false);
  const [cebCompleted, setCebCompleted] = useState(false);
  const [showCompleteButton, setShowCompleteButton] = useState(false);
  const [badgeClaimed, setBadgeClaimed] = useState(false);

  const user = auth.currentUser;

  useEffect(() => {
    const checkCEAandCEBFields = async () => {
      try {
        if (user) {
          const userRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(userRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            const ceaValue = userData.cea;
            const cebValue = userData.ceb;
            const badgeoneValue = userData.badgeone;

            if (ceaValue === 1) {
              setCeaCompleted(true);
            }
            if (cebValue === 1) {
              setCebCompleted(true);
            }

            if (ceaValue === 1 && cebValue === 1) {
              setShowCompleteButton(true);
            }

            if (badgeoneValue === 1) {
              setBadgeClaimed(true);
            }
          }
        }
      } catch (error) {
        console.error('Error checking CEA and CEB fields:', error);
      }
    };

    checkCEAandCEBFields();
  }, [user]);

  const [badgeModalIsOpen, setBadgeModalIsOpen] = useState(false);

  const handleComplete = async () => {
    try {
      const userRef = doc(db, 'users', user.uid);

      // Update the Firestore database with the "badgeone" field set to 1
      await setDoc(userRef, { badgeone: 1 }, { merge: true });

      // Log the successful update (you can remove this if not needed)
      console.log('Badgeone field updated to 1');

      // Update state to indicate badge claimed and open the badge modal
      setBadgeClaimed(true);
      setBadgeModalIsOpen(true);
    } catch (error) {
      console.error('Error updating badgeone field:', error);
    }
  };

  return (
    <div className='wpage'>
      <br></br>
    <div className='epage'>
    <center><h1>2 Numerical Expressions</h1></center><br></br>
    <b>Ready to put your mathematical skills to the test? Dive into our "2 Numerical Expressions" challenge and prove your mastery. Solve two intriguing numerical puzzles to earn the coveted Beginner's Badge – a symbol of your accomplishment and a ticket to uncovering the world of mathematical wonders.

<h2>Claim Your Beginner's Badge:</h2>
Step up to the challenge by cracking both numerical expressions. As you conquer each equation, you'll inch closer to claiming the Beginner's Badge and all the mathematical secrets it unlocks. Sharpen your abilities, embrace the thrill of problem-solving, and showcase your achievement to the world. The Beginner's Badge awaits; are you up for the journey?</b><br></br><br></br>
    <br></br><div className='aniexp'>
    <div className="card">
    <Link to="/CEa" className="button-link">
          <button className={`button ${!user ? 'disabled' : ''}`} disabled={!user}>
            {user ? 'Problem 1' : 'Sign in to do that'}
            <span className={`checkbox ${ceaCompleted ? 'checked' : ''}`}></span>
          </button>
        </Link>
        <Link to="/CEb" className="button-link">
          <button className={`button ${!user ? 'disabled' : ''}`} disabled={!user}>
            {user ? 'Problem 2' : 'Sign in to do that'}
            <span className={`checkbox ${cebCompleted ? 'checked' : ''}`}></span>
          </button>
      </Link>
      {showCompleteButton && !badgeClaimed ? (
        <button className="complete-button" onClick={handleComplete}>
          Complete
        </button>
      ) : (
        badgeClaimed && <p>Claimed</p>
      )}
      <Modal
        isOpen={badgeModalIsOpen}
        onRequestClose={() => setBadgeModalIsOpen(false)}
        contentLabel="Badge Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <h2>You received 1 Beginner's Badge</h2><center>
        <img src={badgeImage} alt="Beginner's Badge" style={{ width: '50px', height: '50px' }} /></center>
        <button onClick={() => setBadgeModalIsOpen(false)}>Close</button>
      </Modal>
    </div><br></br><br></br></div></div><Link to="/" className="back-button">
    <center><button className='bckbttn'>Back</button></center>
  </Link></div>
  );
}

export default CExpressions;
