import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './CExpression.css';
import badgeImage from '../img/badgetwo.png';
import Modal from 'react-modal';
import { db, auth } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

function CGeometry() {
  const [cgaCompleted, setCgaCompleted] = useState(false);
  const [cgbCompleted, setCgbCompleted] = useState(false);
  const [showCompleteButton, setShowCompleteButton] = useState(false);
  const [badgetwoClaimed, setBadgetwoClaimed] = useState(false);

  const user = auth.currentUser;

  useEffect(() => {
    const checkCGAandCGBFields = async () => {
      try {
        if (user) {
          const userRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(userRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            const cgaValue = userData.cga;
            const cgbValue = userData.cgb;
            const badgetwoValue = userData.badgetwo;

            if (cgaValue === 1) {
              setCgaCompleted(true);
            }
            if (cgbValue === 1) {
              setCgbCompleted(true);
            }

            if (cgaValue === 1 && cgbValue === 1) {
              setShowCompleteButton(true);
            }

            if (badgetwoValue === 1) {
              setBadgetwoClaimed(true);
            }
          }
        }
      } catch (error) {
        console.error('Error checking CGA and CGB fields:', error);
      }
    };

    checkCGAandCGBFields();
  }, [user]);

  const [badgeModalIsOpen, setBadgeModalIsOpen] = useState(false);

  const handleComplete = async () => {
    try {
      const userRef = doc(db, 'users', user.uid);

      // Update the Firestore database with the "badgeone" field set to 1
      await setDoc(userRef, { badgetwo: 1 }, { merge: true });

      // Log the successful update (you can remove this if not needed)
      console.log('Badgetwo field updated to 1');

      // Update state to indicate badge claimed and open the badge modal
      setBadgetwoClaimed(true);
      setBadgeModalIsOpen(true);
    } catch (error) {
      console.error('Error updating badgetwo field:', error);
    }
  };

  return (
    <div className='wpage'>
      <br></br>
    <div className='epage'>
    <center><h1>2 Geometry Questions</h1></center><br></br>
    <b>Are you ready to unravel the mysteries of shapes and angles? Step into the realm of geometry with our captivating challenge, "2 Geometry Questions." By solving two intriguing geometry puzzles, you'll prove your expertise and earn the esteemed Geometry Hero Badge – a testament to your mastery and a key to unlocking the world of geometric marvels.

<h2>Become a Geometry Hero:</h2>
Embrace the challenge and embark on a journey of geometric discovery. As you conquer each question, you'll edge closer to the coveted Geometry Hero Badge. Sharpen your analytical skills, embrace the thrill of geometric problem-solving, and showcase your accomplishment proudly. The Geometry Hero Badge is your gateway to a realm of mathematical wonders – are you prepared to embark on this quest?</b><br></br><br></br>
<br></br><div className='aniexp'>
    <div className="card">
        <Link to="/CGa" className="button-link">
          <button className={`button ${!user ? 'disabled' : ''}`} disabled={!user}>
            {user ? 'Problem 1' : 'Sign in to do that'}
            <span className={`checkbox ${cgaCompleted ? 'checked' : ''}`}></span>
          </button>
        </Link>
        <Link to="/CGb" className="button-link">
          <button className={`button ${!user ? 'disabled' : ''}`} disabled={!user}>
            {user ? 'Problem 2' : 'Sign in to do that'}
            <span className={`checkbox ${cgbCompleted ? 'checked' : ''}`}></span>
          </button>
      </Link>
      {showCompleteButton && !badgetwoClaimed ? (
        <button className="complete-button" onClick={handleComplete}>
          Complete
        </button>
      ) : (
        badgetwoClaimed && <p>Claimed</p>
      )}
      <Modal
        isOpen={badgeModalIsOpen}
        onRequestClose={() => setBadgeModalIsOpen(false)}
        contentLabel="Badge Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <h2>You received 1 Geometry Hero Badge</h2><center>
        <img src={badgeImage} alt="Geometry Badge" style={{ width: '50px', height: '50px' }} /></center>
        <button onClick={() => setBadgeModalIsOpen(false)}>Close</button>
      </Modal>
      </div><br></br><br></br></div></div><Link to="/" className="back-button">
      <center><button className='bckbttn'>Back</button></center>
  </Link></div>
  );
}

export default CGeometry;
