import React from 'react';
import './AreaVolumeTutorial.css'; // Optional CSS file for styling

const AreaVolumeTutorial = () => {
  return (
    <div className="AreaVolume-tutorial">
      <h1>Area & Volume Tutorial</h1>
      <div class="video-container">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/E8tuMaDxgJM?si=P29nHAzokXUfNwJM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      {/* Add content for Area & Volume tutorial here */}
      <p><strong>Introduction to Area:</strong></p>
      <p>Area is the measure of the space covered by a two-dimensional shape. It is expressed in square units, such as square inches or square meters. For example, when we talk about the area of a rectangle, we mean the space covered by the rectangle on a flat surface.</p>
      <p>Area is an important concept in geometry and is used to find the size of surfaces in real-life applications, such as calculating the area of a room for flooring or determining the amount of paint needed to cover a wall.</p>

      <p><strong>Calculating Area of Rectangles:</strong></p>
      <p>To find the area of a rectangle, multiply the length by the width. The formula for calculating the area of a rectangle is:</p>
      <p>Area = Length * Width</p>
      <p>For example, if a rectangle has a length of 6 units and a width of 4 units, the area would be:</p>
      <p>Area = 6 * 4 = 24 square units</p>

      <p><strong>Calculating Area of Triangles:</strong></p>
      <p>To find the area of a triangle, multiply the base by the height and then divide the result by 2. The formula for calculating the area of a triangle is:</p>
      <p>Area = (Base * Height) / 2</p>
      <p>For example, if a triangle has a base of 8 units and a height of 5 units, the area would be:</p>
      <p>Area = (8 * 5) / 2 = 20 square units</p>

      <p><strong>Finding Surface Area of Three-Dimensional Figures:</strong></p>
      <p>In three-dimensional figures with rectangular and triangular faces, the surface area is the sum of the areas of all the faces. To find the surface area of these figures, you need to calculate the area of each face and then add them together.</p>
      <p>For example, if we have a cube with all sides of length 3 units, the surface area would be:</p>
      <p>Surface Area = 6 * (side * side) = 6 * (3 * 3) = 54 square units</p>

      <p><strong>Calculating Volume of Right Rectangular Prisms:</strong></p>
      <p>The volume of a right rectangular prism is the measure of the space it encloses. To find the volume of a right rectangular prism, multiply the area of the base by the height. The formula for calculating the volume of a right rectangular prism is:</p>
      <p>Volume (V) = (Area of Base) * Height</p>
      <p>For example, if a right rectangular prism has a base with an area of 10 square units and a height of 5 units, the volume would be:</p>
      <p>Volume = 10 * 5 = 50 cubic units</p>

      <p><strong>First Area & Volume Problem Example:</strong></p>
      <p>Problem: Find the area of a rectangle with a length of 9 units and a width of 5 units.</p>
      <p>Solution:</p>
      <p>To find the area of the rectangle, we use the formula: Area = Length * Width</p>
      <p>Area = 9 * 5 = 45 square units</p>
      <p>So, the area of the rectangle is 45 square units.</p>

      <p><strong>Second Area & Volume Problem Example:</strong></p>
      <p>Problem: Calculate the volume of a right rectangular prism with a base area of 12 square units and a height of 8 units.</p>
      <p>Solution:</p>
      <p>To find the volume of the prism, we use the formula: Volume (V) = (Area of Base) * Height</p>
      <p>Volume = 12 * 8 = 96 cubic units</p>
      <p>So, the volume of the right rectangular prism is 96 cubic units.</p>
      
      {/* Add more sections as needed */}
    </div>
  );
};

export default AreaVolumeTutorial;
