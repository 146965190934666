import React from 'react';
import './RatiosTutorial.css'; // Optional CSS file for styling

const RatiosTutorial = () => {
  return (
    <div className="ratios-tutorial">
      <h1>Ratios Tutorial</h1>
      <div class="video-container">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/EWLLHgTq1Ag?si=mD-9xku0rddW4HG0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      {/* Add content for Ratios tutorial here */}
      <p><strong>Introduction to Ratios:</strong></p>
      <p>A ratio is a comparison of two quantities that have the same unit. It represents how many times one quantity is contained in another. Ratios are often written in the form of "a : b" or "a/b" to show the relationship between the two quantities.</p>
      <p>Ratios are used in various real-world scenarios, such as in cooking recipes, map scales, and financial calculations. They help us understand the relative sizes of different quantities and make comparisons.</p>

      <p><strong>Representing Ratios:</strong></p>
      <p>To represent ratios, we use appropriate language to describe the relationship between the two quantities. For example:</p>
      <ul>
        <li>"The ratio of apples to oranges is 3 to 2."</li>
        <li>"The ratio of boys to girls in a class is 2 to 5."</li>
        <li>"The ratio of blue marbles to red marbles in a bag is 4 : 7."</li>
      </ul>

      <p><strong>Making Tables of Equivalent Ratios:</strong></p>
      <p>In a ratio, equivalent ratios are those that represent the same comparison but have different values. To make tables of equivalent ratios, you can multiply or divide both quantities in the ratio by the same number.</p>
      <p>For example, if we have a ratio of 2 : 3, the table of equivalent ratios can be:</p>
      <table>
        <thead>
          <tr>
            <th>Ratio</th>
            <th>Equivalent Ratio</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>2 : 3</td>
            <td>4 : 6</td>
          </tr>
          <tr>
            <td>2 : 3</td>
            <td>6 : 9</td>
          </tr>
          <tr>
            <td>2 : 3</td>
            <td>8 : 12</td>
          </tr>
        </tbody>
      </table>

      <p><strong>Finding Missing Values in Tables of Ratios:</strong></p>
      <p>Tables of equivalent ratios can be used to find missing values. Given one ratio in the table, you can use it to find the missing value in another ratio. This is particularly useful when dealing with real-world problems that involve proportional relationships.</p>
      <p>For example, if we know that 4 : 6 is an equivalent ratio to 2 : 3, we can use this information to find the missing value in the ratio 2 : 4. Since the first ratio was multiplied by 2 to get the second ratio, we can do the same for the missing value:</p>
      <p>2 : 4 is an equivalent ratio to 1 : 2.</p>

      <p><strong>Solving Proportion Problems:</strong></p>
      <p>Proportions are statements that two ratios are equal. Solving proportion problems involves finding the unknown value in a proportion.</p>
      <p>For example, in the proportion 3 : 6 = x : 12, we need to find the value of x. To do this, we cross-multiply and solve for x:</p>
      <p>3 * 12 = 6 * x</p>
      <p>36 = 6x</p>
      <p>x = 6</p>
      <p>So, the value of x is 6.</p>

      <p><strong>Describing Rates and Unit Rates:</strong></p>
      <p>A rate is a special type of ratio that compares two quantities with different units. For example, "miles per hour" or "dollars per pound."</p>
      <p>A unit rate is a rate in which the second quantity is 1 unit. It simplifies the comparison by showing how much of one quantity corresponds to 1 unit of the other quantity.</p>
      <p>For example, if a car travels 60 miles in 2 hours, the rate is 60 miles / 2 hours = 30 miles per hour. The unit rate in this case is 30 miles per hour.</p>

      <p><strong>First Ratios Problem Example:</strong></p>
      <p>Problem: In a bag of marbles, the ratio of red marbles to blue marbles is 2 : 5. If there are 15 blue marbles, how many red marbles are there?</p>
      <p>Solution:</p>
      <p>To find the number of red marbles, we can set up a proportion:</p>
      <p>2 : 5 = x : 15</p>
      <p>Now, cross-multiply and solve for x:</p>
      <p>2 * 15 = 5 * x</p>
      <p>30 = 5x</p>
      <p>x = 6</p>
      <p>So, there are 6 red marbles in the bag.</p>

      <p><strong>Second Ratios Problem Example:</strong></p>
      <p>Problem: In a recipe, the ratio of flour to sugar is 3 : 2. If we use 6 cups of flour, how many cups of sugar do we need?</p>
      <p>Solution:</p>
      <p>To find the number of cups of sugar, we set up a proportion:</p>
      <p>3 : 2 = 6 : x</p>
      <p>Now, cross-multiply and solve for x:</p>
      <p>3 * x = 2 * 6</p>
      <p>3x = 12</p>
      <p>x = 4</p>
      <p>So, we need 4 cups of sugar.</p>
      
      {/* Add more sections as needed */}
    </div>
  );
};

export default RatiosTutorial;
