import { Link } from 'react-router-dom';
import './Home.css';
import '../App.css';
import React, { useState } from 'react';
import mathcbckg from '../img/mathcbckg.jpg';


const Home = () => {
  const [activeCard, setActiveCard] = useState(null);
  const [showChart, setShowChart] = useState(false);
  const [showStandardsText, setShowStandardsText] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [email, setEmail] = useState('');
  const [nametwo, setNametwo] = useState('');
  const [gradeLevel, setGradeLevel] = useState('');

  

  // Function to handle card clicks
  const handleCardClick = (cardType) => {
    setActiveCard(cardType);
    
    // Update visibility states based on the clicked card
    setShowChart(cardType === 'curriculum');
    setShowStandardsText(cardType === 'standards');
    setShowForm(cardType === 'freeTutoring');
  };

;

  return (    
    <div className="landing-page">
       <nav className="navbar">
        <Link to="/leaderboard" className="navbar-link">Leaderboard</Link>
      </nav>
    <section className="hero">

    <div className="card-container">
          <Link to="/CExpressions">
          <div className="card card-bg fixed-card-height" style={{ backgroundImage: `url(${mathcbckg})` }}>
              <div className="card-title">2 Numerical Expressions</div>
              <div className="card-grade">Complete 2 questions about expressions and earn the Beginner's Badge.</div>
            </div>
          </Link>
          <Link to="/CGeometry">
          <div className="card card-bg fixed-card-height" style={{ backgroundImage: `url(${mathcbckg})` }}>
              <div className="card-title">2 Geometry Questions</div>
              <div className="card-grade">2 Geometry questions frequently used in school Quizes for the Geometry Hero Badge.</div>
            </div>
          </Link>
          <Link to="/CExponents">
          <div className="card card-bg fixed-card-height" style={{ backgroundImage: `url(${mathcbckg})` }}>
              <div className="card-title">Familiar With Exponents</div>
              <div className="card-grade">Answer 3 questions to guage your skills using exponents. You will receive the Exponential Excellence Badge. </div>
            </div>
          </Link>
          <Link to="/">
          <div className="card card-bg fixed-card-height" style={{ backgroundImage: `url(${mathcbckg})` }}>
              <div className="card-title">Top 5 Algebra Questions</div>
              <div className="card-grade">Complete the Top 5 Algebra questions used in school and get the Algebra Hero Badge. </div>
              <div className="overlay-text">Locked</div>
            </div>
          </Link></div>

          
          <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="overlay-texttwo">Locked
  <button className='badgemore'>More Badges</button>
</div></div>


          <br></br>
        
      <div className="hero-content">
      
        <div className="intro-text">
          <br></br>
          <b>Standardized Tutorials</b>
        </div>
        <Link to="/AreaVolumeTutorial">
          <button className="tutorial-button AreaaVolume-tutorial">
            Area & Volume Tutorial <span className="grade yellow">6th Grade</span>
          </button>
        </Link>
        <Link to="/RatiosTutorial">
          <button className="tutorial-button ratios-tutorial">
            Ratio Tutorial <span className="grade yellow">6th Grade</span>
          </button>
        </Link>
        <Link to="/SlopeTutorial">
          <button className="tutorial-button slope-tutorial">
            Slope Tutorial <span className="grade orange">7th Grade</span>
          </button>
        </Link>
        <Link to="/StatisticsTutorial">
          <button className="tutorial-button statistics-tutorial">
            Statistics Tutorial <span className="grade orange">7th Grade</span>
          </button>
        </Link>
        <Link to="/ProbabilityTutorial">
          <button className="tutorial-button probability-tutorial">
            Probability Tutorial <span className="grade orange">7th Grade</span>
          </button>
        </Link>
        <Link to="/TransformationsTutorial">
          <button className="tutorial-button transformations-tutorial">
            Transformations Tutorial <span className="grade red">8th Grade</span>
          </button>
        </Link>
        <Link to="/FunctionsTutorial">
          <button className="tutorial-button functions-tutorial">
            Functions Tutorial <span className="grade red">8th Grade</span>
          </button>
        </Link>
        <Link to="/ScientificNotationTutorial">
          <button className="tutorial-button scientific-tutorial">
            Scientific Notation Tutorial <span className="grade red">8th Grade</span>
          </button>
        </Link>
      </div>
    </section>
    <br></br><center>
    <div className="card-containerfbot">
        <div
          className={`cardfbot ${activeCard === 'curriculum' ? 'active' : ''}`}
          onClick={() => handleCardClick('curriculum')}
        >
          Curriculum
        </div>
        <div
          className={`cardfbot ${activeCard === 'standards' ? 'active' : ''}`}
          onClick={() => handleCardClick('standards')}
        >
          Standards
        </div>
        <div
          className={`cardfbot ${activeCard === 'freeTutoring' ? 'active' : ''}`}
          onClick={() => handleCardClick('freeTutoring')}
        >
          Free Tutoring
        </div>
      </div></center>

    <br></br>
    {showChart && (
  <div>
    <div className="main-heading">Georgia Department of Education GSE</div>
    <b>GALEET conforms to the standards set by the DOE.</b>
    <br></br>

    <div className="chart-heading">6th Grade</div>
    <div className="chart-container">
  <div className="chart-row header">
    <div className="chart-cell">Unit</div>
    <div className="chart-cell">Duration</div>
    <div className="chart-cell">Content</div>
  </div>
  <div className="chart-row">
    <div className="chart-cell">Unit 1</div>
    <div className="chart-cell">4 – 5 weeks</div>
    <div className="chart-cell">Number System Fluency</div>
  </div>
  <div className="chart-row">
    <div className="chart-cell">Unit 2</div>
    <div className="chart-cell">3 – 4 weeks</div>
    <div className="chart-cell">Rate, Ratio and Proportional Reasoning Using Equivalent Fractions</div>
  </div>
  <div className="chart-row">
    <div className="chart-cell">Unit 3</div>
    <div className="chart-cell">4 – 5 weeks</div>
    <div className="chart-cell">Expressions</div>
  </div>
  <div className="chart-row">
    <div className="chart-cell">Unit 4</div>
    <div className="chart-cell">4 – 5 weeks</div>
    <div className="chart-cell">One-Step Equations and Inequalities</div>
  </div>
  <div className="chart-row">
    <div className="chart-cell">Unit 5</div>
    <div className="chart-cell">4 – 5 weeks</div>
    <div className="chart-cell">Statistics</div>
  </div>
  <div className="chart-row">
    <div className="chart-cell">Unit 6</div>
    <div className="chart-cell">3 – 4 weeks</div>
    <div className="chart-cell">Rational Explorations: Numbers and their Opposites</div>
  </div>
</div>

    <div className="chart-heading">7th Grade</div>
    <div className="chart-container">
  <div className="chart-row header">
    <div className="chart-cell">Unit</div>
    <div className="chart-cell">Duration</div>
    <div className="chart-cell">Content</div>
  </div>
  <div className="chart-row">
    <div className="chart-cell">Unit 1</div>
    <div className="chart-cell">4 – 5 weeks</div>
    <div className="chart-cell">Operations with Rational Numbers</div>
  </div>
  <div className="chart-row">
    <div className="chart-cell">Unit 2</div>
    <div className="chart-cell">4 – 5 weeks</div>
    <div className="chart-cell">Expressions and Equations</div>
  </div>
  <div className="chart-row">
    <div className="chart-cell">Unit 3</div>
    <div className="chart-cell">4 – 5 weeks</div>
    <div className="chart-cell">Ratios and Proportional Relationships</div>
  </div>
  <div className="chart-row">
    <div className="chart-cell">Unit 4</div>
    <div className="chart-cell">4 – 5 weeks</div>
    <div className="chart-cell">Geometry</div>
  </div>
  <div className="chart-row">
    <div className="chart-cell">Unit 5</div>
    <div className="chart-cell">4 – 5 weeks</div>
    <div className="chart-cell">Inferences</div>
  </div>
  <div className="chart-row">
    <div className="chart-cell">Unit 6</div>
    <div className="chart-cell">3 – 4 weeks</div>
    <div className="chart-cell">Probability</div>
  </div>
</div>

<div className="chart-heading">8th Grade</div>
    <div className="chart-container">
  <div className="chart-row header">
    <div className="chart-cell">Unit</div>
    <div className="chart-cell">Duration</div>
    <div className="chart-cell">Content</div>
  </div>
  <div className="chart-row">
    <div className="chart-cell">Unit 1</div>
    <div className="chart-cell">4 – 5 weeks</div>
    <div className="chart-cell">Transformations, Congruence and Similarity</div>
  </div>
  <div className="chart-row">
    <div className="chart-cell">Unit 2</div>
    <div className="chart-cell">4 – 5 weeks</div>
    <div className="chart-cell">Exponents and Equations</div>
  </div>
  <div className="chart-row">
    <div className="chart-cell">Unit 3</div>
    <div className="chart-cell">4 – 5 weeks</div>
    <div className="chart-cell">Geometric Applications of Exponents</div>
  </div>
  <div className="chart-row">
    <div className="chart-cell">Unit 4</div>
    <div className="chart-cell">2 – 3 weeks</div>
    <div className="chart-cell">Functions</div>
  </div>
  <div className="chart-row">
    <div className="chart-cell">Unit 5</div>
    <div className="chart-cell">3 – 4 weeks</div>
    <div className="chart-cell">Linear Functions</div>
  </div>
  <div className="chart-row">
    <div className="chart-cell">Unit 6</div>
    <div className="chart-cell">5 – 6 weeks</div>
    <div className="chart-cell">Linear Models and Tables</div>
  </div>
  <div className="chart-row">
    <div className="chart-cell">Unit 7</div>
    <div className="chart-cell">4 – 5 weeks</div>
    <div className="chart-cell">Solving Systems of Equations</div>
  </div>
</div>
</div>
)}
{showStandardsText && (
  <div>
    <div class="chart">
    <h2>Sixth Grade Standards</h2>
    <p>The nine standards listed below are the key content competencies students will be expected to master in sixth grade...</p>
    <ul>
      <li>6.MP: Display perseverance and patience in problem-solving...</li>
      <li>6.NR.1: Solve relevant, mathematical problems involving operations with whole numbers, fractions, and decimal numbers...</li>
      <li>6.NR.2: Apply operations with whole numbers, fractions and decimals within relevant applications...</li>
      <li>6.NR.3: Solve a variety of problems involving whole numbers and their opposites; model rational numbers on a number line to describe problems presented in relevant, mathematical situations...</li>
      <li>6.NR.4: Solve a variety of contextual problems involving ratios, unit rates, equivalent ratios, percentages, and conversions within measurement systems using proportional reasoning...</li>
      <li>6.GSR.5: Solve relevant problems involving area, surface area, and volume...</li>
      <li>6.PAR.6: Identify, write, evaluate, and interpret numerical and algebraic expressions as mathematical models to explain relevant situations...</li>
      <li>6.PAR.7: Write and solve one-step equations and inequalities as mathematical models to explain authentic, realistic situations...</li>
      <li>6.PAR.8: Graph rational numbers as points on the coordinate plane to represent and solve contextual, mathematical problems; draw polygons using the coordinates for their vertices and find the length of a side of a polygon...</li>
    </ul>
  </div>

  <div class="chart">
    <h2>Seventh Grade Standards</h2>
    <p>The seven standards listed below are the key content competencies students will be expected to master in seventh grade...</p>
    <ul>
      <li>7.MP: Display perseverance and patience in problem-solving...</li>
      <li>7.NR.1: Solve relevant, mathematical problems, including multi-step problems, involving the four operations with rational numbers and quantities in any form (integers, percentages, fractions, and decimal numbers)...</li>
      <li>7.PAR.2: Use properties of operations, generate equivalent expressions and interpret the expressions to explain relevant situations...</li>
      <li>7.PAR.3: Represent authentic situations using equations and inequalities with variables; solve equations and inequalities symbolically, using the properties of equality...</li>
      <li>7.PAR.4: Recognize proportional relationships in relevant, mathematical problems; represent, solve, and explain these relationships with tables, graphs, and equations...</li>
      <li>7.GSR.5: Solve practical problems involving angle measurement, circles, area of circles, surface area of prisms and cylinders, and volume of cylinders and prisms composed of cubes and right prisms...</li>
      <li>7.PR.6: Using mathematical reasoning, investigate chance processes and develop, evaluate, and use probability models to find probabilities of simple events presented in authentic situations...</li>
    </ul>
  </div>

  <div class="chart">
    <h2>Eighth Grade Standards</h2>
    <p>The eight standards listed below are the key content competencies students will be expected to master in eighth grade...</p>
    <ul>
      <li>8.MP: Display perseverance and patience in problem-solving...</li>
      <li>8.NR.1: Solve problems involving irrational numbers and rational approximations of irrational numbers to explain realistic applications...</li>
      <li>8.NR.2: Solve problems involving radicals and integer exponents including relevant application situations; apply place value understanding with scientific notation and use scientific notation to explain real phenomena...</li>
      <li>8.PAR.3: Create and interpret expressions within relevant situations. Create, interpret, and solve linear equations and linear inequalities in one variable to model and explain real phenomena...</li>
      <li>8.PAR.4: Show and explain the connections between proportional and non-proportional relationships, lines, and linear equations; create and interpret graphical mathematical models and use the graphical, mathematical model to explain real phenomena represented in the graph...</li>
      <li>8.FGR.5: Describe the properties of functions to define, evaluate, and compare relationships, and use functions and graphs of functions to model and explain real phenomena...</li>
      <li>8.FGR.6: Solve practical, linear problems involving situations using bivariate quantitative data...</li>
      <li>8.FGR.7: Justify and use various strategies to solve systems of linear equations to model and explain realistic phenomena...</li>
      <li>8.GSR.8: Solve contextual, geometric problems involving the Pythagorean Theorem and the volume of geometric figures to explain real phenomena...</li>
    </ul>
  </div>
  </div>
)}

{showForm && (
  <div>
    <b>Sign Up for Free Tutoring</b>
          <div className="form-container">
        <h2>Virtual Tutoring With Certified Georgia Teacher</h2>
        <form action="https://formsubmit.co/georgialeetmath@gmail.com" method="POST">
          <div className="form-group">
              <label>Name:</label>
              <input
                name="name"
                id='name'
                type="name"
                value={nametwo}
                onChange={(e) => setNametwo(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Email:</label>
              <input
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Child's Grade Level:</label>
              <input
                name="gradeLevel"
                type="number"
                value={gradeLevel}
                onChange={(e) => setGradeLevel(e.target.value)}
                required
              />
            </div>
            <button type="submit" name='submit'>Submit</button>
          </form>
        </div>
  </div>
)}



   <br></br><br></br>
<div>2023 Copyright Georgia Leet Math <Link to="/Privacypolicya">Privacy Policy</Link></div>
    </div>
  );
};

export default Home;
