import React from 'react';
import './ProbabilityTutorial.css'; // Optional CSS file for styling

const ProbabilityTutorial = () => {
  return (
    <div className="probability-tutorial">
       <h1>Probability Tutorial</h1>
      {/* Add content for Probability tutorial here */}
      <p><strong>Introduction to Probability:</strong></p>
      <p>Probability is a measure of the likelihood of an event occurring. It is expressed as a number between 0 and 1, where 0 indicates an unlikely event, 1 indicates a certain event, and values in between represent varying degrees of likelihood.</p>
      <p>Probability is used in many real-world scenarios, such as weather forecasting, sports predictions, and games of chance.</p>

      <p><strong>Experimental and Theoretical Probability:</strong></p>
      <p>In probability, we often distinguish between experimental probability and theoretical probability.</p>
      <p>Experimental probability is an estimate of the likelihood of an event based on the outcomes observed in a series of trials. For example, if we flip a coin 100 times and get heads 60 times, the experimental probability of getting heads is 60/100 = 0.6.</p>
      <p>Theoretical probability, on the other hand, is based on mathematical calculations and assumes that all outcomes are equally likely. For example, in a fair six-sided die, the theoretical probability of rolling a 3 is 1/6 ≈ 0.167.</p>

      <p><strong>Developing Probability Models:</strong></p>
      <p>To develop probability models, we can use different approaches:</p>
      <ol>
        <li>Uniform Probability Model: Assign equal probability to all outcomes.</li>
        <li>Observing Frequencies: Observe frequencies in data generated from a chance process.</li>
      </ol>
      <p>For example, if we roll a fair six-sided die 100 times and record the frequencies of each number rolled, we can use those frequencies to develop a probability model.</p>

      <p><strong>Approximating Probability:</strong></p>
      <p>We can approximate the probability of an event by collecting data through repeated trials and observing its long-run relative frequency. As the number of trials increases, the experimental probability approaches the theoretical probability.</p>

      <p><strong>Comparing Experimental and Theoretical Probability:</strong></p>
      <p>After developing a probability model, we can compare the experimental and theoretical probabilities of events. If the probabilities are close, it indicates that the model is accurate. If there are discrepancies, we should investigate possible sources of error or bias in the data.</p>

      <p><strong>Using Probability Models:</strong></p>
      <p>Probability models can be used to find probabilities of simple events. We can also use appropriate graphical displays and numerical summaries from data distributions with categorical or quantitative variables as probability models to draw informal inferences about two samples or populations.</p>

      <p><strong>Conclusion:</strong></p>
      <p>Probability is a powerful tool for understanding uncertainty and making predictions. By developing and using probability models, we can make informed decisions and draw conclusions based on data.</p>

      <p><strong>Problem 1: Coin Toss Probability</strong></p>
      <p>Suppose you have a fair coin (a coin with equal chances of landing heads or tails). You toss the coin 50 times and record the outcomes. You get 30 heads and 20 tails.</p>
      <p><strong>Step 1: Representing Probability as a Number</strong></p>
      <p>Calculate the experimental probability of getting heads and tails based on the outcomes observed.</p>
      {/* Add calculations for experimental probability */}
      <p>Experimental Probability of Heads = 0.6</p>
      <p>Experimental Probability of Tails = 0.4</p>

      <p><strong>Step 2: Comparing Experimental and Theoretical Probability</strong></p>
      <p>The theoretical probability of getting heads or tails in a fair coin toss is 0.5 (since there are two equally likely outcomes: heads and tails).</p>
      <p>Compare the experimental and theoretical probabilities and explain any discrepancies.</p>

      <p><strong>Step 3: Interpretation</strong></p>
      <p>The experimental probability of getting heads is 0.6, which is close to the theoretical probability of 0.5. However, the experimental probability of getting tails is 0.4, which is slightly different from the theoretical probability. This difference could be due to random chance in the small sample size or other factors affecting the coin toss.</p>

      <p><strong>Problem 2: Rolling Dice Probability</strong></p>
      <p>You have a six-sided fair die. You roll the die 100 times and record the frequencies of each number rolled:</p>
      <p>Number 1: 15 times</p>
      <p>Number 2: 20 times</p>
      <p>Number 3: 25 times</p>
      <p>Number 4: 10 times</p>
      <p>Number 5: 20 times</p>
      <p>Number 6: 10 times</p>

      <p><strong>Step 1: Developing a Probability Model</strong></p>
      <p>Use the observed frequencies to develop a probability model for rolling each number on the die.</p>
      {/* Add calculations for probability model */}
      <p>Probability of Rolling a 1 = 0.15</p>
      <p>Probability of Rolling a 2 = 0.2</p>
      <p>Probability of Rolling a 3 = 0.25</p>
      <p>Probability of Rolling a 4 = 0.1</p>
      <p>Probability of Rolling a 5 = 0.2</p>
      <p>Probability of Rolling a 6 = 0.1</p>

      <p><strong>Step 2: Comparing Experimental and Theoretical Probability</strong></p>
      <p>Compare the developed probability model with the theoretical probability of rolling each number on a fair six-sided die (1/6 for each number).</p>

      <p><strong>Step 3: Interpretation</strong></p>
      <p>By developing a probability model from the observed frequencies, we can estimate the likelihood of rolling each number on the die. The model can be compared to the theoretical probabilities to check for accuracy.</p>

      {/* Add more sections as needed */}
    </div>
  );
};

export default ProbabilityTutorial;
